import { extern, Player as ShakaPlayer, util } from '@shakaPlayer';
import { SECOND } from '../../../../consts/date';
import { DEFAULT_RENEW_LICENSE_BEFORE_EXPIRATION } from '../../../../consts/license';
import { getLicenseExpiration } from '../../../../utils/license';
import { ShakaPlayerEventType } from '../types/ShakaPlayerEventType';
import { LicenseStorage, PersistentLicenseOptions, SessionData } from './types';
import {
    findSessionDataBySessionId,
    removeSessionDataFromStorage
} from './utils/license';
import { isValidTimer } from './utils/timer';

class PersistentLicense implements extern.PersistentLicenseInterface {
    private readonly shakaPlayer: ShakaPlayer;
    private readonly storage: LicenseStorage;
    private readonly renewLicenseBeforeExpiration: number;
    private licenseRenewalTimerId: number | null;

    constructor(opt: PersistentLicenseOptions) {
        this.shakaPlayer = opt.dependencies.shakaPlayer;
        this.storage = opt.dependencies.storage;

        const renewLicenseBeforeExpiration = opt.settings.renewLicenseBeforeExpiration
            ?? DEFAULT_RENEW_LICENSE_BEFORE_EXPIRATION;

        this.renewLicenseBeforeExpiration =
            renewLicenseBeforeExpiration * SECOND;

        this.licenseRenewalTimerId = null;

        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.Error,
            this.onShakaError
        );
    }

    private get licenseStorage(): Record<string, SessionData> | null {
        return this.storage.getStorage();
    }

    private get persistentLicenseId(): string | null {
        const drmInfo = this.shakaPlayer.drmInfo();
        const keyIds = drmInfo && drmInfo.keyIds;

        if (keyIds !== null) {
            return keyIds.values().next().value as string;
        }

        return null;
    }

    public destroy(): Promise<void> {
        this.clearLicenseRenewalTimer();

        this.shakaPlayer.removeEventListener(
            ShakaPlayerEventType.Error,
            this.onShakaError
        );

        /**
         * dirty hack as long as PersistentLicenseInterface extends Destroyable interface
         * from shaka-player, and this interface define return type of destroy() method as Promise
         */
        return Promise.resolve();
    }

    public getSessionId(): string | null {
        const sessionData = this.getSessionData();

        if (!sessionData) {
            return null;
        }

        if (!this.canLicenseBeRestored(sessionData.expiration)) {
            this.removeSessionData(sessionData.sessionId);

            return '';
        }

        return sessionData.sessionId;
    }

    public onSessionRestore(session: MediaKeySession): void {
        const sessionData = findSessionDataBySessionId(
            this.licenseStorage,
            session.sessionId
        );

        if (!sessionData) {
            return;
        }

        this.scheduleLicenseRenewal(sessionData.expiration);
    }

    public removeSessionData(sessionId: string): void {
        if (!this.licenseStorage) {
            return;
        }

        const newLicenseStorage = removeSessionDataFromStorage(
            this.licenseStorage,
            sessionId
        );

        this.updateLicenseStorage(newLicenseStorage);
    }

    public setSessionData(session: MediaKeySession): void {
        if (!this.persistentLicenseId) {
            return;
        }

        const { sessionId, expiration } = session;

        const storage = this.licenseStorage || {};

        const sessionData = {
            sessionId,
            expiration: getLicenseExpiration(expiration),
        };

        this.updateLicenseStorage({
            ...storage,
            [this.persistentLicenseId]: sessionData,
        });

        this.scheduleLicenseRenewal(sessionData.expiration);
    }

    private readonly onShakaError = (errorEvent: Event): void => {
        const error = errorEvent.detail as util.Error;
        const errorCategory = error.category as number;

        if (errorCategory !== util.Error.Category.DRM) {
            return;
        }

        const sessionData = this.getSessionData();

        sessionData && this.removeSessionData(sessionData.sessionId);
    };

    private clearLicenseRenewalTimer(): void {
        if (!this.licenseRenewalTimerId) {
            return;
        }

        window.clearTimeout(this.licenseRenewalTimerId);

        this.licenseRenewalTimerId = null;
    }

    private getSessionData(): SessionData | null {
        if (!this.licenseStorage || !this.persistentLicenseId) {
            return null;
        }

        return this.licenseStorage[this.persistentLicenseId] as SessionData | null;
    }

    private canLicenseBeRestored(expiration: number): boolean {
        return expiration - Date.now() > this.renewLicenseBeforeExpiration * 2;
    }

    private scheduleLicenseRenewal(expiration: number): void {
        this.clearLicenseRenewalTimer();

        const timer = this.getLicenseRenewalTimer(expiration);

        if (!isValidTimer(timer)) {
            return;
        }

        this.licenseRenewalTimerId = window.setTimeout(() => {
            const sessionData = this.getSessionData();

            if (!sessionData || !sessionData.sessionId) {
                return;
            }

            void this.shakaPlayer.renewDrmLicense(sessionData.sessionId);
        }, timer);
    }

    private updateLicenseStorage(storageData: Record<string, SessionData> | null): void {
        if (!storageData) {
            return;
        }

        this.storage.setItem(storageData);
    }

    private getLicenseRenewalTimer(expiration: number): number {
        return expiration - this.renewLicenseBeforeExpiration - Date.now();
    }
}

export default PersistentLicense;
