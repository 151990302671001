interface CompareFn<T> {
  (a: T, b: T): number;
}

const findAndInsert= <T>(arr: T[], item: T, compareFn: CompareFn<T>): number => {
  let start = 0;
  let end = arr.length;

  while (start < end) {
    const mid = (start + end) >> 1;

    const middle = arr[mid];

    if (!middle) {
      return -1;
    }

    const result = compareFn(item, middle);

    if (result === 0) {
      return mid;
    } else if (result < 0) {
      end = mid;
    } else {
      start = mid + 1;
    }
  }

  return end;
};

export const insertSorted = <T>(arr: T[], item: T, compareFn: CompareFn<T>): T[] => {
  const array = [...arr];

  const index = findAndInsert(array, item, compareFn);

  array.splice(index, 0, item);

  return array;
};