import ManifestTracks from '../../../externalPlayer/implementations/shakaExternalPlayer/models/manifestTracks';
import {
    PlayerAdBreak,
    PlayerAdBreaksInfo,
    PlayerAdBreaksParserOptions,
    PlayerScteSegment
} from '../../../shared';
import {
    createAccessibilityTracksList,
    getAdaptationSetsWithAudioDescription,
    getAdaptationSetsWithSubtitleHardOfHearingFeature,
    getManifestType
} from '../../manifest';
import DashAdBreaksParserFactory, { AdBreaksParserOptions } from './adBreaksParsers/adBreaksParserFactory';
import { DashAdBreaksParser } from './adBreaksParsers/defaultAdBreaksParser';

interface ManifestData {
    playerAdBreaks: PlayerAdBreak[];
    audioDescriptionTracks: string[];
    subtitleHardOfHearingTracks: string[];
    playerScteSegments: PlayerScteSegment[];
    audioManifestTracks: ManifestTracks | null;
    subtitlesManifestTracks: ManifestTracks | null;
    manifestType: string | null;
}

export default class DashManifestParser {
    private adBreakParser: DashAdBreaksParser;

    constructor (opt: AdBreaksParserOptions) {
        this.adBreakParser = DashAdBreaksParserFactory.create(opt);
    }

    private parseAdBreaks(manifest: Document, options:PlayerAdBreaksParserOptions): PlayerAdBreaksInfo {
        return this.adBreakParser.parseAdBreaks(manifest, options);

    }

    parseManifest(manifest: string, options: PlayerAdBreaksParserOptions): ManifestData {
        const parsedManifest = new DOMParser().parseFromString(manifest, 'text/xml');
        const adaptationNodes = parsedManifest.querySelectorAll('AdaptationSet');
        const manifestType = getManifestType(parsedManifest);
        const adaptationNodesWithAudioDescription = getAdaptationSetsWithAudioDescription(adaptationNodes);
        const adaptationNodesWithSubtitleHardOfHearing = getAdaptationSetsWithSubtitleHardOfHearingFeature(adaptationNodes);
          
        const audioDescriptionTracks = createAccessibilityTracksList(adaptationNodesWithAudioDescription);
        const subtitleHardOfHearingTracks = createAccessibilityTracksList(adaptationNodesWithSubtitleHardOfHearing);
    
        const { adBreaks, segments } = this.parseAdBreaks(parsedManifest, options);

        const audioManifestTracks = this.adBreakParser.parseManifestAudioTracks(parsedManifest, adBreaks);
        const subtitlesManifestTracks = this.adBreakParser.parseManifestSubtitlesTracks(parsedManifest, adBreaks);

        return {
            playerAdBreaks: adBreaks,
            audioDescriptionTracks,
            subtitleHardOfHearingTracks,
            playerScteSegments: segments || [],
            audioManifestTracks,
            subtitlesManifestTracks,
            manifestType,
        };
    }
}