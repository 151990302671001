import { Player as ShakaPlayer } from '@shakaPlayer';
import ExternalPlayer from '../../externalPlayer';
import { ShakaExternalPlayerOptions } from './shakaExternalPlayer';
import ShakaDashExternalPlayer from './shakaExternalPlayer.dash';
import ShakaHlsExternalPlayer from './shakaExternalPlayer.hls';
import ShakaExternalPlayerFallback from './shakaExternalPlayerFallback';

interface ShakaExternalPlayerFactoryOptions extends ShakaExternalPlayerOptions {
  readonly platform: Bowser.Parser.Parser;
}

export default class ShakaExternalPlayerFactory {
  private constructor() {
    //private
  }

  public static create(opt: ShakaExternalPlayerFactoryOptions): ExternalPlayer {
    if (window.__flutter_web_player_emulate_browser_is_not_supported
      || !ShakaPlayer.isBrowserSupported()) {
      return new ShakaExternalPlayerFallback();
    }

    const Ctr = opt.platform.isBrowser('safari')
      ? ShakaHlsExternalPlayer
      : ShakaDashExternalPlayer;

    return new Ctr({
      playerLogger: opt.playerLogger,
      transformUrl: opt.transformUrl,
      parseOpportunity: opt.parseOpportunity,
      parsePlacement: opt.parsePlacement,
      getDrmCertificate: opt.getDrmCertificate,
      getLicense: opt.getLicense,
      shakaPlayer: opt.shakaPlayer,
      customLicenseProtocol: opt.customLicenseProtocol,
      parseScte: opt.parseScte,
      storage: opt.storage,
      segmentsRecoveryService: opt.segmentsRecoveryService,
      keySystemPriority: opt.keySystemPriority,
      isAdPlayer: opt.isAdPlayer,
      getSessionManagerUrl: opt.getSessionManagerUrl,
      videoContainer: opt.videoContainer,
      targetOs: opt.targetOs,
    });
  }
}