import { GlobalLogger, ILogger, ILogHandler } from 'js-logger';
import { PlayerEventTarget } from '../../events/playerEvent';
import PlayerLogger from '../logger';
import { PlayerLoggerEventType, PlayerLoggerLevelChagedEvent } from '../loggerEvent';

interface LoggerOptions {
  readonly source: GlobalLogger;
  readonly window: Window;
  readonly loggerLevelLabel: string;
  readonly defaultLevel: string;
}

export default class BasePlayerLogger extends PlayerEventTarget<PlayerLoggerEventType> implements PlayerLogger {
  private readonly label;
  private readonly window: Window;
  private readonly source: GlobalLogger;

  private readonly style: string = 'background-color: #333; padding: 3px; color: #bada55';

  constructor(options: LoggerOptions) {
    super();

    this.label = options.loggerLevelLabel;
    this.window = options.window;
    this.source = options.source;

    this.source.useDefaults({
      defaultLevel: this.getDefaultLevel(options.defaultLevel),
      formatter: this.formatMessage,
    });

    this.window.addEventListener('hashchange', this.onHashChanged);
    this.onHashChanged();
  }

  private getDefaultLevel(envLevel: string) {
    return envLevel === this.source.DEBUG.name
      ? this.source.DEBUG
      : this.source.OFF;
  }

  private readonly onHashChanged = (): void => {
    this.trackLoggerLevel();
    this.dispatchEvent(new PlayerLoggerLevelChagedEvent(this.isEnabled));
  };

  private readonly formatMessage: ILogHandler = (messages, context) => {
    const name = context.name ? ` * [${context.name}]` : '';

    messages.unshift(this.style);
    messages.unshift(`%c${new Date().toLocaleTimeString()}${name}`);
  };

  private trackLoggerLevel(): void {
    const { hash } = this.window.location;
    const normalizedHash = hash.replace('#', '');
    const urlSearchParams = new URLSearchParams(normalizedHash);

    const labelValue = urlSearchParams.get(this.label);

    if (!labelValue) {
      return;
    }

    switch (labelValue) {
      case this.source.TRACE.name:
        return this.source.setLevel(this.source.TRACE);//.error .warn .time .info .debug .trace [1]
      case this.source.DEBUG.name:
        return this.source.setLevel(this.source.DEBUG);//.error .warn .time .info .debug [2]
      case this.source.INFO.name:
        return this.source.setLevel(this.source.INFO);//.error .warn .time .info [3]
      case this.source.TIME.name:
        return this.source.setLevel(this.source.TIME);//.error .warn .time [4]
      case this.source.WARN.name:
        return this.source.setLevel(this.source.WARN);//.error .warn [5]
      case this.source.ERROR.name:
        return this.source.setLevel(this.source.ERROR);//.error [8]
      case this.source.OFF.name:
        return this.source.setLevel(this.source.OFF);//nothing [99]
    }
  }

  public get isEnabled(): boolean {
    return this.source.getLevel().value !== this.source.OFF.value;
  }

  public dispose(): void {
    this.window.removeEventListener('hashchange', this.onHashChanged);
  }

  public forName(name: string): ILogger {
    return this.source.get(name);
  }
}