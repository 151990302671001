import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { ParseOpportunity, ParsePlacement, ParseScte } from '../../../../shared';
import { BasicDashAdBreaksParserStrategy } from './basicAdBreaksParser';
import { DashAdBreaksParser, DefaultDashAdBreaksParserStrategy } from './defaultAdBreaksParser';
import { DpgDashAdBreaksParserStrategy } from './dpgDashAdBreaksParserStrategy';
import FfwdDashAdBreaksParserStrategy from './ffwdAdBreaksParser';
import { Gt12ChDashAdBreaksParserStrategy } from './gt12ChAdBreaksParser';
import VodDashAdBreaksParserStrategy from './vodAdBreaksParser';
export interface AdBreaksParserOptions {
    readonly parserType: AdBreaksParsingType;
    readonly parseScte: ParseScte;
    readonly parsePlacement: ParsePlacement;
    readonly parseOpportunity: ParseOpportunity;
}

export default class DashAdBreaksParserFactory {
    public static create(opt: AdBreaksParserOptions): DashAdBreaksParser {
        switch (opt.parserType) {
            case AdBreaksParsingType.basic: 
                return new BasicDashAdBreaksParserStrategy();
            case AdBreaksParsingType.vod: 
                return new VodDashAdBreaksParserStrategy({
                    parsePlacement: opt.parsePlacement,
                    parseOpportunity: opt.parseOpportunity,
                });
            case AdBreaksParsingType.gt12:
                return new Gt12ChDashAdBreaksParserStrategy({
                    parseScte: opt.parseScte,
                    parseOpportunity: opt.parseOpportunity,
                    parsePlacement: opt.parsePlacement,
                });
            case AdBreaksParsingType.ffwd:
                return new FfwdDashAdBreaksParserStrategy({
                    parseOpportunity: opt.parseOpportunity,
                    parsePlacement: opt.parsePlacement,
                });
            case AdBreaksParsingType.dpg:
                return new DpgDashAdBreaksParserStrategy({
                    parseScte: opt.parseScte,
                    parseOpportunity: opt.parseOpportunity,
                    parsePlacement: opt.parsePlacement,
                });
            case AdBreaksParsingType.none:
            default:
                return new DefaultDashAdBreaksParserStrategy();
        }
    }
  }