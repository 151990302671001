export enum ProtectionSystemType {
  Widevine = 'com.widevine.alpha',
  Playready = 'com.microsoft.playready',
  Fairplay_1 = 'com.apple.fps.1_0',
}

export enum KeySystem {
  Widevine = 'widevine',
  Playready = 'playready'
}

/**
 * @see https://dashif.org/identifiers/content_protection/
 */
export enum ProtectionSystemIndentifier {
  Widevine = 'edef8ba9-79d6-4ace-a3c8-27dcd51d21ed',
  Playready = '9a04f079-9840-4286-ab92-e65be0885f95',
  Fairplay = '94ce86fb-07ff-4f43-adb8-93d2fa968ca2',
}

export const ProtectionSystemTypeToId = {
  [ProtectionSystemType.Widevine]: ProtectionSystemIndentifier.Widevine,
  [ProtectionSystemType.Playready]: ProtectionSystemIndentifier.Playready,
  [ProtectionSystemType.Fairplay_1]: ProtectionSystemIndentifier.Fairplay,
};

export const KeySystemToProtectionSystemType = {
  [KeySystem.Playready]: ProtectionSystemType.Playready,
  [KeySystem.Widevine]: ProtectionSystemType.Widevine,
};

export const drmSecurityLevel = {
  [ProtectionSystemType.Widevine]: 'Widevine Undefined',
  [ProtectionSystemType.Playready]: 'PlayReady Undefined',
  [ProtectionSystemType.Fairplay_1]: 'FairPlay Version Undefined',
};
