const getAdBreakOnlyTracks = (adBreaks: string[], mainContent: string[]): Record<string, boolean> => {
  return adBreaks.reduce((acc: Record<string, boolean>, lang) => {
    if (mainContent.includes(lang)) {
      return acc;
    }

    acc[lang] = true;

    return acc;
  }, {});
};

export default class ManifestTracks {
  public readonly adBreaks: string[];
  public readonly mainContent: string[];
  public readonly adBreaksOnlyTracksMap: Record<string, boolean>;

  constructor(adBreaks: string[], mainContent: string[]) {
    this.adBreaks = adBreaks;
    this.mainContent = mainContent;

    this.adBreaksOnlyTracksMap = getAdBreakOnlyTracks(adBreaks, mainContent);
  }
}
