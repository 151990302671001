import { propEq } from 'ramda';
import { SessionData } from '../types';

export const findSessionDataBySessionId = (
    storage: Record<string, SessionData> | null,
    sessionId: string
): SessionData | null => {
    if (!storage) {
        return null;
    }

    const sessionData = Object.values(storage).find(propEq('sessionId', sessionId));

    if (!sessionData) {
        return null;
    }

    return sessionData;
};

export const removeSessionDataFromStorage = (
    storage: Record<string, SessionData> | null,
    sessionId: string
): Record<string, SessionData> | null => {
    if (!storage) {
        return null;
    }

    const newStorage = Object
        .entries(storage)
        .reduce((storageAccumulator, [key, sessionData]) => {
            if (sessionData.sessionId !== sessionId) {
                return {
                    ...storageAccumulator,
                    [key]: sessionData,
                };
            }

            return storageAccumulator;
        }, {});
    
    return newStorage;
};
