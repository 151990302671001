import { AnalyticMessageArguments } from '../shared';

const separator = ' ';

export const buildAnalyticMessage = (arg: AnalyticMessageArguments): string => {
    const stringBuilder = [
        arg.component,
        arg.code,
        arg.name,
    ].filter(Boolean);
    
    if(Array.isArray(arg.data)) {
        const details = arg.data.filter(Boolean);
        
        details.length && stringBuilder.push(details.join(separator));
    }
    
    return stringBuilder.join(separator);    
};
