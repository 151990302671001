import { test } from 'ramda';
import { extern } from '@shakaPlayer';

const INIT = /init/i;
const HEADER = /header/i;

const isInit = test(INIT);
const isHeader = test(HEADER);

class Segment {
    public id: string;
    public quality: string;
    public data: ArrayBuffer | ArrayBufferView;
    public type: string;

    constructor(response: extern.Response) {
        const urlSegments = response.uri.split('/');

        this.id = urlSegments[urlSegments.length - 1] ?? '';
        this.quality = urlSegments[urlSegments.length - 2] ?? '';
        this.type = response.headers['content-type'] ?? '';
        this.data = response.data;
    }

    isEqual(segment: Segment): boolean {
        return this.id === segment.id && this.type === segment.type;
    }

    toString(): string {
        return '(' + this.type + ')' + this.quality + ':' + this.id;
    }

    isInitChunk(): boolean {
        return isInit(this.id) || isHeader(this.id);
    }
}

export default Segment;
