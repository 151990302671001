import ManifestTracks from '../../../../externalPlayer/implementations/shakaExternalPlayer/models/manifestTracks';
import { PlayerAdBreak, PlayerAdBreaksInfo, PlayerAdBreaksParserOptions } from '../../../../shared';

export interface DashAdBreaksParser {
    parseAdBreaks(manifest: Document, options?: PlayerAdBreaksParserOptions): PlayerAdBreaksInfo;
    
    parseManifestAudioTracks(manifest: Document, adBreaks: PlayerAdBreak[]): ManifestTracks | null;

    parseManifestSubtitlesTracks(manifest: Document, adBreaks: PlayerAdBreak[]): ManifestTracks | null;
}

export class DefaultDashAdBreaksParserStrategy implements DashAdBreaksParser {
    public parseManifestAudioTracks(): null {
        return null;
    }

    public parseManifestSubtitlesTracks(): null {
        return null;
    }

    public parseAdBreaks(): PlayerAdBreaksInfo {
        return { adBreaks: [] };
    }
}