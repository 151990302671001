import { AdBreaksParsingType } from '../../consts/adBreaks';
import { HlsAdBreaksParserOptions, ParseScte, PlayerAdBreak } from '../../shared';
import { getUnixTimestampInMs } from '../date';
import { fromHexToBase64 } from '../string';
import {
  filterAdBreaks,
  filterStartTime,
  findChunkByName,
  getChunks,
  getStreamInfoTags
} from './utils';

const SECOND = 1000;

const isShortenedDate = (date: string) => date.indexOf('T') === 7;

const formatDate = (date: string) => {
  if (!isShortenedDate(date)) {
    return date;
  }

  return date.slice(0, 7).concat('-01', date.slice(7));
};

/**
 * Trims header and quotation marks
 * @example
 * START-DATE="2019-01T00:15:00Z" -> 2019-01T00:15:00Z
 */
export const parseStartDate = (str: string): string => formatDate(str.slice(12, -1));

/**
 * Trims header and quotation marks
 * @example
 * END-DATE="2019-01T00:15:00Z" -> 2019-01T00:15:00Z
 */
export const parseEndDate = (str: string): string => formatDate(str.slice(10, -1));

/**
 * Trims header 'DURATION='
 * @example
 * DURATION=60.000 -> 60.000
 */
export const parseDuration = (str: string): number => Number(str.slice(9));

/**
 * Trims header 'SCTE35-OUT=' and prefix '0x'
 * @example
 * SCTE35-OUT=0xFC305E00000000000000FFF005...-> FC305E00000000000000FFF005...
 */
export const parseScteOut = (str = ''): string => str.slice(13);

/**
 * Trims header 'SCTE35-IN=' and prefix '0x'
 * @example
 * SCTE35-IN=0xFC305E00000000000000FFF005...-> FC305E00000000000000FFF005...
 */
export const parseScteIn = (str = ''): string => str.slice(12);

/**
 * Trims header '#EXT-X-PROGRAM-DATE-TIME:'
 * @param { string } str
 * @example
 * #EXT-X-PROGRAM-DATE-TIME:2019-01-01T00:10:02.616Z -> 2019-01-01T00:10:02.616Z
 */
export const parsePlaylistProgramDateTime = (str: string): string => formatDate(str.slice(25));

/**
 * get all possible mediaPlaylists data from MasterPlaylist
 * Please read the specification,
 * EXT-X-STREAM-INF:
 * https://tools.ietf.org/html/draft-pantos-http-live-streaming-21#section-4.3.4.2
 *
 * Expected only MasterPlaylist as playlistData.
 *
 * EXAMPLE:
 * https://developer.apple.com/documentation/http_live_streaming/example_playlists_for_http_live_streaming/creating_a_master_playlist
 */
export const getMediaPlaylistsData = (playlistData = '', url = ''): { url: string }[] => {
  const streamTags = getStreamInfoTags(playlistData);

  return streamTags.map((tag: string) => {

    const [, relativeUrl = ''] = getChunks(tag);
    const lastSlashIndex = url.lastIndexOf('/');
    const absoluteUrl = url.substring(0, lastSlashIndex + 1) + relativeUrl;

    return { url: absoluteUrl };
  });
};

export const getMediaPlaylistsDataUrl = (playlistData = '', url = ''): string[] => {
  return getMediaPlaylistsData(playlistData, url).map(data => data.url);
};

/**
 *
 * The EXT-X-PROGRAM-DATE-TIME tag associates the first sample of a
 * Media Segment with an absolute date and/or time.  It applies only to
 * the next Media Segment.  Its format is:
 *
 * #EXT-X-PROGRAM-DATE-TIME:<date-time-msec>
 *
 * 4.3.2.6.  EXT-X-PROGRAM-DATE-TIME:
 * https://datatracker.ietf.org/doc/html/rfc8216#section-4.3.2.6
 */
export const getPlaylistStart = (playlistData = ''): number | null => {
  const keyLine = getChunks(playlistData, filterStartTime)[0];

  if (!keyLine) {
    return null;
  }

  const startDate = parsePlaylistProgramDateTime(keyLine);

  return getUnixTimestampInMs(startDate);
};

type _AdBreakRange = { startTime: number, endTime: number | undefined };

/**
 * If ad break has valid END-DATE or DURATION than we should use it.
 *
 * Else we should check the position of the next ad break and do the following:
 *
 * a2. Consider previous EXT-X-DATERANGE as a valid Ad Break. Which has position:
 * start = Previous_EXT-X-DATERANGE.START-DATE - EXT-X-PROGRAM-DATE-TIME
 * end = Current_EXT-X-DATERANGE.START-DATE - EXT-X-PROGRAM-DATE-TIME
 *
 * see
 * https://wikiprojects.upc.biz/display/PERS/Linear+Ad+Breaks+-+Basic#LinearAdBreaksBasic-HLS-AdBreaksDetection
 */
export const getAdBreakEnd = (adBreak: _AdBreakRange, nextAdBreak: _AdBreakRange | undefined): number | null => {
  if (adBreak.endTime) {
    return adBreak.endTime;
  }

  if (!nextAdBreak) {
    return null;
  }

  if (nextAdBreak.startTime !== adBreak.startTime) {
    return nextAdBreak.startTime;
  }

  return null;
};


/**
 * Returns an array with ad breaks start and end.
 *
 * The EXT-X-DATERANGE tag associates a Date Range (i.e., a range of
 * time defined by a starting and ending date) with a set of attribute/
 *  value pairs.  Its format is:
 *
 * #EXT-X-DATERANGE: <attribute-list>
 *
 * EXT-X-DATERANGE:
 * https://tools.ietf.org/html/rfc8216#section-4.3.2.7
 *
 * EXAMPLE:
 * #EXT-X-DATERANGE:ID="splice-6FFFFFF0",START-DATE="2019-01T00:15:00Z",DURATION=60.000,SCTE35-OUT=0xF
 *
 * Client Algorithm for Ad break detection:
 * https://wikiprojects.upc.biz/display/PERS/Linear+Ad+Breaks+-+Basic#LinearAdBreaksBasic-HLS-AdBreaksDetection
 */
export const getAdBreaksInfo = (
  playlistData = '',
  parseScte: ParseScte = () => null,
  ignoredScteTypes: number[] = []
): PlayerAdBreak[] => {
  return getChunks(playlistData, filterAdBreaks)
    .map((keyLine: string) => keyLine.split(','))
    .reduce((result: _AdBreakRange[], chunks: string[]): _AdBreakRange[] => {
      const startDateAttr = findChunkByName(chunks, 'START-DATE=');

      if (!startDateAttr) {
        return result;
      }

      const scte35OutAttr = findChunkByName(chunks, 'SCTE35-OUT=');
      const scte35InAttr = findChunkByName(chunks, 'SCTE35-IN=');

      const scteBase64String = fromHexToBase64(
        parseScteOut(scte35OutAttr) || parseScteIn(scte35InAttr)
      );


      let parsedScteBase64String;

      try {
        parsedScteBase64String = parseScte(scteBase64String, true) || '';
      } catch (e) {
        parsedScteBase64String = '';
      }

      const scteSegmentationTypeId = Number(parsedScteBase64String);

      if (ignoredScteTypes.includes(scteSegmentationTypeId)) {
        return result;
      }

      const durationAttr = findChunkByName(chunks, 'DURATION=');
      const endDateAttr = findChunkByName(chunks, 'END-DATE=');

      const startDate = parseStartDate(startDateAttr);
      const start = getUnixTimestampInMs(startDate);

      if (endDateAttr) {
        const endDate = parseEndDate(endDateAttr);
        const end = getUnixTimestampInMs(endDate);

        return [...result, { startTime: start, endTime: end }];
      }

      if (!durationAttr) {
        return [...result, { startTime: start, endTime: undefined }];
      }

      const duration = parseDuration(durationAttr);

      const end = start + duration * SECOND;

      return [...result, { startTime: start, endTime: end }];
    }, [])
    .reduce((result: PlayerAdBreak[], adBreak: _AdBreakRange, i: number, list: _AdBreakRange[]): PlayerAdBreak[] => {
      const nextAdBreak = list[i + 1];

      const endPosition = getAdBreakEnd(adBreak, nextAdBreak);

      if (endPosition) {
        return [...result, { startTime: adBreak.startTime, endTime: endPosition }];
      }

      return result;
    }, []);
};

export const convertAdBreaksToRelativeTime = (adBreaks: PlayerAdBreak[], playlistStartTime: number): PlayerAdBreak[] => {
  return adBreaks.map(({ startTime, endTime }) => ({
    startTime: startTime - playlistStartTime,
    endTime: endTime - playlistStartTime,
  }));
};

export const getBasicAdBreaksRelativeTimeInfo = (options: HlsAdBreaksParserOptions): PlayerAdBreak[] => {
  const { manifest, parseScte, ignoredScteTypes } = options;

  const playlistStart = getPlaylistStart(manifest);

  if (!playlistStart) {
    return [];
  }

  const adBreakInfo = getAdBreaksInfo(manifest, parseScte, ignoredScteTypes);

  return convertAdBreaksToRelativeTime(adBreakInfo, playlistStart)
    .map((ad) => ({
      startTime: ad.startTime,
      endTime: ad.endTime,
      adType: AdBreaksParsingType.basic,
    }));
};
