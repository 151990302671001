import { AdBreaksParsingType, OpportunityType } from '../../../../consts/adBreaks';
import { SegmentationType } from '../../../../consts/segmentationTypes';
import { PlayerScteSegment, PlayerAdBreak, OpportunityInfo } from '../../../../shared';
import LinearAdBreaksParserService from '../linearAvadAdBreakParser/linearAvadAdBreakParser';

export const createGt12PlayerAdBreaks = (segments: PlayerScteSegment[], type: AdBreaksParsingType): PlayerAdBreak[] =>
    segments.reduce((acc: PlayerAdBreak[], segment: PlayerScteSegment) => {
        if (segment.segmentationTypeId !== SegmentationType.B_START) {
            return acc;
        }

        const { startTime, endTime } = segment;

        const segmentD = segments.find((segment: PlayerScteSegment) => {
            if (segment.segmentationTypeId !== SegmentationType.D_START) {
                return false;
            }

            return endTime && segment.startTime >= endTime;
        });

        const adBreak = {
            startTime,
            endTime,
            adType: type,
            markerD: segmentD?.startTime,
        };

        return [...acc, adBreak];
    }, []);

export default class Gt12ChAvadHlsAdBreaksParser extends LinearAdBreaksParserService {
    static readonly AD_TYPE = AdBreaksParsingType.gt12;

    protected get adBreaksType(): AdBreaksParsingType {
        return Gt12ChAvadHlsAdBreaksParser.AD_TYPE;
    }

    protected checkOpportunityType(opportunityInfo: OpportunityInfo): boolean {
        return opportunityInfo.type === OpportunityType.preRoll;
    }

    protected getPlayerAdBreaksFromSegments(segments: PlayerScteSegment[]): PlayerAdBreak[] {
        return createGt12PlayerAdBreaks(segments, this.adBreaksType);
    }
}