import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { HlsAdBreaksParserOptions, ParseOpportunity, ParsePlacement, PlayerAdBreaksInfo } from '../../../../shared';
import InsertedAdBreaksParserService from '../insertedAdBreaksParser/insertedAdBreaksParser';

export interface FfwdHlsAdBreakParser {
  parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo;
}
export interface FfwdAdBreakParserDeps {
  readonly parseOpportunity: ParseOpportunity;
  readonly parsePlacement: ParsePlacement;
}

/**
 * Inserted ad-breaks are added into the original asset by video head end components(MDC). 
 * 
 * HLS Ffwd ad breaks parser.
 * 
 * see:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads#InsertedAds-Opportunity-HLS
 */
export default class FfwdAdBreakParserService extends InsertedAdBreaksParserService {
  static readonly AD_TYPE = AdBreaksParsingType.ffwd;

  protected get adBreaksType(): AdBreaksParsingType {
    return FfwdAdBreakParserService.AD_TYPE;
  }

  public parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo {
    const adBreaks = this.parseInsertedAdBreaks(options.textTrackList);
    
    return { adBreaks };
  } 
}