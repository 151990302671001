
export type DateRangeData = {
  event?: string, 
  dataType?: string, 
  dataEncoding?: string, 
  data?: string,
  opId?: string,
};

/**
 * Value of DateRange attribute formatted as Semicolon Separated Key Pair Values by Colon (sskpvcol).
 * 
 * Client App must parse value of X-COM-DMDSDP-AVAD into internal data structure
 * where naming for each key must be saved.
 * 
 * see:
 * https://wikiprojects.upc.biz/display/PERS/HLS+AVAD+Data#HLSAVADData-X-COM-DMDSDP-AVAD
 */
export const parseDataCueData = (data: string): DateRangeData => {
    const splittedData = data.split(';')
      .map((info) => info.split(':'))
      .reduce((acc: DateRangeData, [keyStr, value]) => {
        const key = keyStr as keyof DateRangeData;
  
        if (!key) {
          return acc;
        }
  
        acc[key] = value;
  
        return acc;
      }, {});
  
    return splittedData;
  };