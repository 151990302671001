export const getChunks = (playlistData: string, filterFunction?:(a: string) => boolean): string[] => {
  const playlistDataChunks = playlistData.split('\n');

  if (!filterFunction) {
    return playlistDataChunks;
  }

  return playlistDataChunks.filter(filterFunction);
};

export const filterAdBreaks = (data:string): boolean => data.startsWith('#EXT-X-DATERANGE');
export const filterStartTime = (data:string): boolean => data.startsWith('#EXT-X-PROGRAM-DATE-TIME');

export const findChunkByName = (chunks: string[], name:string): string | undefined => 
  chunks.find(chunk => chunk && chunk.startsWith(name));

/**
 * get all stream info tags from playlist
 * #EXT-X-STREAM-INF:<attribute-list>
 * <URI>
 *
 * please read:
 * https://tools.ietf.org/html/draft-pantos-http-live-streaming-16#section-4.3.4.2
 *
 */
export const getStreamInfoTags = (playlist: string): string[] => {
  return playlist
    .split('#')
    .filter((chunk) => chunk.startsWith('EXT-X-STREAM-INF'))
    .map((chunk) => chunk.trim());
};