import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import ManifestTracks from '../../../../externalPlayer/implementations/shakaExternalPlayer/models/manifestTracks';
import { PlayerAdBreak, PlayerAdBreaksInfo } from '../../../../shared';
import { getAllManifestPeriods } from '../../../manifest';
import { getManifestAudioTracks, getManifestSubtitlesTracks } from '../../../manifest/dash';
import InsertedAdBreaksParser from './insertedAdBreaksParser';


/**
 * Vod ad breaks parsing strategy for DASH Manifest
 * 
 * see Opportunity - DASH and Placement - DASH in:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads
 */
export default class VodDashAdBreaksParser extends InsertedAdBreaksParser {
    static AD_TYPE = AdBreaksParsingType.vod;

    protected get adBreaksType(): AdBreaksParsingType {
        return VodDashAdBreaksParser.AD_TYPE;
    }

    public parseAdBreaks(manifest: Document): PlayerAdBreaksInfo {
        const periodNodes = getAllManifestPeriods(manifest);

        if (!periodNodes) {
            return {
              adBreaks: []
          };
        }

        const adBreaks = this.parseInsertedAdBreaks(periodNodes);
        
        return { adBreaks };
    } 

    public parseManifestAudioTracks(manifest: Document, adBreaks: PlayerAdBreak[]): ManifestTracks {
        return getManifestAudioTracks(manifest, adBreaks);
    }

    public parseManifestSubtitlesTracks(manifest: Document, adBreaks: PlayerAdBreak[]): ManifestTracks {
        return getManifestSubtitlesTracks(manifest, adBreaks);
    }
}