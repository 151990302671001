import { both, complement, gt, is, __ } from 'ramda';

const MAX_TIMER_VALUE = 2147483648;

const isNumber = is(Number);

export const isValidNumber = both(isNumber, complement(isNaN));

const isPositiveNumber = both(isValidNumber, gt(__, 0));

export const isValidTimer = both(isPositiveNumber, gt(MAX_TIMER_VALUE));
