import { PlayerEvent } from '../events/playerEvent';

export enum PlayerLoggerEventType {
  LevelChanged = 'levelchanged',
}

export class PlayerLoggerLevelChagedEvent extends PlayerEvent {
  constructor(public readonly isEnabled: boolean) {
    super(PlayerLoggerEventType.LevelChanged);
  }
}