export enum AdBreaksParsingType {
  none = 'none',
  basic = 'basic',
  vod = 'vod',
  gt12 = 'gt12',
  dpg = 'dpg',
  ffwd = 'ffwd',
}

export enum OpportunityType {
  preRoll = 'preRoll',
  midRoll = 'midRoll',
  postRoll = 'postRoll'
}

export enum PlacementCategory {
  promo = 'Promotion',
  none = 'none'
}