/**
 * Converts value to percentage value.
 * Result is restricted to be in [0, 100] range.
 *
 * @param {Number} value - value to convert
 * @returns {Number}
 */
export const toPercentage = (ratio: number): number => {
	const persentage = ratio * 100;

	if (!persentage) {
		return 0;
	}

	return Math.max(0, Math.min(persentage, 100));
};


/**
 * Returns percentage ratio between values.
 * Result is restricted to be in [0, 100] range.
 *
 * @param {Number} part  - value to be treated as part
 * @param {Number} whole - value to be treated as whole
 * @returns {Number}
 */
export const percentageRatio = (part: number, whole: number): number => toPercentage(part / whole);