import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { HlsAdBreaksParserOptions, ParseOpportunity, ParsePlacement, PlayerAdBreaksInfo } from '../../../../shared';
import InsertedAdBreaksParserService from '../insertedAdBreaksParser/insertedAdBreaksParser';

export interface VodHlsAdBreakParser {
  parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo;
}
export interface VodAdBreakParserDeps {
  readonly parseOpportunity: ParseOpportunity;
  readonly parsePlacement: ParsePlacement;
}

/**
 * Inserted ad-breaks are added into the original asset by video head end components(MDC). 
 * 
 * HLS Vod ad breaks parser.
 * 
 * see:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads#InsertedAds-Opportunity-HLS
 */
export default class VodAdBreakParserService extends InsertedAdBreaksParserService {
  static readonly AD_TYPE = AdBreaksParsingType.vod;

  protected get adBreaksType(): AdBreaksParsingType {
    return VodAdBreakParserService.AD_TYPE;
  }

  public parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo {
    return {
      adBreaks: this.parseInsertedAdBreaks(options.textTrackList)
    };
  }
}