import { SECOND } from '../../../../consts/date';
import { DEFAULT_RENEW_LICENSE_BEFORE_EXPIRATION } from '../../../../consts/license';
import { isValidTimer } from '../persistentLicense/utils/timer';
import { LicenseRenewal, LicenseRenewalInfo, LicenseRenewalSettings } from './types';

export default class LicenseRenewalManager implements LicenseRenewal {
  private licenseRenewalTimerId: number | null = null;
  private readonly renewLicenseBeforeExpiration: number;

  constructor(settings: LicenseRenewalSettings) {
    const renewLicenseBeforeExpiration = settings.renewLicenseBeforeExpiration
      ?? DEFAULT_RENEW_LICENSE_BEFORE_EXPIRATION;

    this.renewLicenseBeforeExpiration = renewLicenseBeforeExpiration * SECOND;
  }

  scheduleLicenseRenewal({ onLicenseRenewalNeeded, expiration }: LicenseRenewalInfo): void {
    this.clearTimer();

    if (!expiration) {
      return;
    }

    const timer = this.getRenewalTimer(expiration);

    if (!isValidTimer(timer)) {
      return;
    }

    this.licenseRenewalTimerId = window.setTimeout(() => {
      onLicenseRenewalNeeded();
    }, timer);
  }

  stop(): void {
    this.clearTimer();
  }

  private clearTimer(): void {
    if (!this.licenseRenewalTimerId) {
      return;
    }

    window.clearTimeout(this.licenseRenewalTimerId);

    this.licenseRenewalTimerId = null;
  }

  private getRenewalTimer(expiration: number): number {
    return expiration - this.renewLicenseBeforeExpiration - Date.now();
  }
}