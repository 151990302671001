import { AdBreaksParsingType, OpportunityType } from '../../../../consts/adBreaks';
import { SegmentationType } from '../../../../consts/segmentationTypes';
import { ParseOpportunity, ParsePlacement, ParseScte, PlayerAdBreak, PlayerAdBreaksInfo, PlayerAdBreaksParserOptions, PlayerScteSegment } from '../../../../shared';
import { getAllManifestPeriods, getEventNode, getEventStreamNode, PeriodInfo } from '../../../manifest';
import InsertedAdBreaksParser from './insertedAdBreaksParser';

const SECOND_IN_MS = 1000;

export interface GtAdBreakParserDeps {
    readonly parseScte: ParseScte;
    readonly parsePlacement: ParsePlacement;
    readonly parseOpportunity: ParseOpportunity;
}

export class Gt12ChDashAdBreaksParserStrategy extends InsertedAdBreaksParser {
    /**
     * Period is considered as Ad-Break if it is Segment B (its segmentationTypeId is 30)
     * @see https://wikiprojects.upc.biz/display/PERS/Linear+Ad+Breaks+-+GT-12#LinearAdBreaksGT12-MainAsset-SegmentsIndicationinManifest-DASH-SegmentB
     */
    static ATTR_AD_ID = 'urn:mime:text';
    static OPPORTUNITY_PRE_ROLL = OpportunityType.preRoll;
    private readonly parseScte: ParseScte;

    constructor(deps: GtAdBreakParserDeps) {
        super(deps);
        this.parseScte = deps.parseScte;
    }

    protected get adBreaksType(): AdBreaksParsingType {
        return AdBreaksParsingType.gt12;
    }

    protected checkOpportunityType(type?: OpportunityType): boolean {
        return type === Gt12ChDashAdBreaksParserStrategy.OPPORTUNITY_PRE_ROLL;
    }

    public parseAdBreaks(manifest: Document, options: PlayerAdBreaksParserOptions): PlayerAdBreaksInfo {
        const periodInfo = getAllManifestPeriods(manifest);

        if (!periodInfo) {
            return {
                adBreaks: [],
            };
        }

        const segments = periodInfo.reduce((acc: PlayerScteSegment[], periodInfo: PeriodInfo): PlayerScteSegment[] => {
            const { period } = periodInfo;
            const eventStreamNode = getEventStreamNode(period);

            if (!eventStreamNode) {
                return acc;
            }

            const schemeIdUri = eventStreamNode.getAttribute('schemeIdUri');

            if (schemeIdUri !== Gt12ChDashAdBreaksParserStrategy.ATTR_AD_ID) {
                return acc;
            }

            const event = getEventNode(eventStreamNode);

            if (!event) {
                return acc;
            }

            const data = event.textContent || '';
            const startTime = periodInfo.start;
            const endTime = startTime + (periodInfo.duration || SECOND_IN_MS);

            const id = this.parseScte(data);
            const scteSegment = {
                startTime,
                endTime,
                segmentationTypeId: id && `0x${id}`,
            };

            return [...acc, { ...scteSegment }];
        }, []);

        const adBreaks = segments.reduce((acc: PlayerAdBreak[], segment: PlayerScteSegment): PlayerAdBreak[] => {
            const { segmentationTypeId, startTime, endTime } = segment;

            if (segmentationTypeId !== SegmentationType.B_START) {
                return acc;
            }

            const markerD = segments.find((currentSegment: PlayerScteSegment): boolean => {
                return currentSegment.segmentationTypeId === SegmentationType.D_START && currentSegment.startTime > segment.startTime;
            });

            const playerAdBreak = {
                startTime,
                endTime,
                adType: AdBreaksParsingType.gt12,
                markerD: markerD?.startTime,
            };

            return [...acc, playerAdBreak];
        }, []);

        const shouldParsePreRoll = options.shouldParsePreRoll ?? false;

        let preRoll: PlayerAdBreak[] = [];

        if (shouldParsePreRoll) {
            preRoll = this.parseInsertedAdBreaks(periodInfo);
        }

        const allBreaks = [...preRoll, ...adBreaks];

        const sortedAdBreaks = allBreaks.sort((adBreak1: PlayerAdBreak, adBreak2: PlayerAdBreak): number =>
            adBreak1.startTime - adBreak2.startTime
        );

        return {
            adBreaks: sortedAdBreaks,
            segments,
        };
    }
}