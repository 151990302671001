import { PlayerEventType } from './eventTypes';
import { PlayerEvent } from './playerEvent';

export enum PictureInPictureState {
    Active = 'active',
    Inactive = 'inactive',
    Unavailable = 'unavailable',
}

export class PictureInPictureStateUpdateEvent extends PlayerEvent {
    public readonly state: PictureInPictureState = PictureInPictureState.Unavailable;

    constructor() {
        super(PlayerEventType.PictureInPictureStateUpdate);
    }
}

export class PictureInPictureActiveStateEvent extends PictureInPictureStateUpdateEvent {
    state = PictureInPictureState.Active;
}

export class PictureInPictureInactiveStateEvent extends PictureInPictureStateUpdateEvent {
    state = PictureInPictureState.Inactive;
}

export class PictureInPictureUnavailableStateEvent extends PictureInPictureStateUpdateEvent {
    state = PictureInPictureState.Unavailable;
}