import { PlayerEventTarget } from '../../events/playerEvent';
import IReactiveValue from '../reactiveValue';
import { ReactiveValueChangeEvent, ReactiveValueEventType } from '../reactiveValueEvent';

export default class ReactiveValue<T> extends PlayerEventTarget<ReactiveValueEventType> implements IReactiveValue<T> {
    private readonly initValue: T;
    public currentValue: T;

    constructor(initValue: T) {
        super();

        this.initValue = initValue;
        this.currentValue = initValue;
    }

    public reset(): void {
        this.currentValue = this.initValue;
    }

    public set(receivedValue: T | null | undefined): void {
        const previous = this.currentValue;

        this.currentValue = receivedValue ?? previous;

        this.hasChanged(previous) && this.emitChange();
    }

    private emitChange(): void {
        this.dispatchEvent(new ReactiveValueChangeEvent());
    }

    private hasChanged(previous: T): boolean {
        return this.currentValue !== previous;
    }
}
