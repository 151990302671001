import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { DpgSegmentationType } from '../../../../consts/segmentationTypes';
import { PlayerAdBreak, PlayerScteSegment } from '../../../../shared';
import LinearAdBreaksParserService from '../linearAvadAdBreakParser/linearAvadAdBreakParser';

const isDpgAdBreak = ({ segmentationTypeId }: PlayerScteSegment): boolean => 
    segmentationTypeId == DpgSegmentationType.BREAK || segmentationTypeId == DpgSegmentationType.PROVIDER_AD;

export const createDpgPlayerAdBreaks = (segments: PlayerScteSegment[], type: AdBreaksParsingType): PlayerAdBreak[] =>
    segments
    .filter(isDpgAdBreak)
    .map((segment: PlayerScteSegment) => {
        const { startTime, endTime } = segment;
  
        const adBreak = {
            startTime,
            endTime,
            adType: type,
        };
  
        return adBreak;
    });

export default class DpgAvadAdBreakParser extends LinearAdBreaksParserService {
    static readonly AD_TYPE = AdBreaksParsingType.dpg;

    protected get adBreaksType(): AdBreaksParsingType {
        return DpgAvadAdBreakParser.AD_TYPE;
    }

    protected getPlayerAdBreaksFromSegments(segments: PlayerScteSegment[]): PlayerAdBreak[] {
        return createDpgPlayerAdBreaks(segments, this.adBreaksType);
    }
}