export enum OS {
    tizen = 'tizen',
    webOS = 'webos',
}

export function isWebTv(targetOs: string): boolean {
    return targetOs === OS.tizen || targetOs === OS.webOS;
}

export function isWebOs(targetOs: string): boolean {
    return targetOs === OS.webOS;
}

export function isTizen(targetOs: string): boolean {
    return targetOs === OS.tizen;
} 