import { PlayerEvent } from '../events/playerEvent';

export enum ReactiveValueEventType {
  Change = 'change',
}

export class ReactiveValueChangeEvent extends PlayerEvent {
  constructor() {
    super(ReactiveValueEventType.Change);
  }
}