
import { EventEmitter } from 'events';

export class PlayerEvent {
  constructor(public readonly type: string) { }
}

export interface PlayerEventListener<T extends PlayerEvent> {
  (event: T): void;
}

export class PlayerEventTarget<T extends string> extends EventEmitter {
  public addEventListener<E extends PlayerEvent>(eventType: T, eventHandler: PlayerEventListener<E>): void {
    this.on(eventType, eventHandler);
  }

  public removeEventListener<E extends PlayerEvent>(eventType: T, eventHandler: PlayerEventListener<E>): void {
    this.off(eventType, eventHandler);
  }

  public dispatchEvent(event: PlayerEvent): void {
    this.emit(event.type, event);
  }
}