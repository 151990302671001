export enum SegmentationType {
  A_START = '0x10',
  A_END = '0x11',
  B_START = '0x30',
  B_END = '0x31',
  C_START = '0x32',
  C_END = '0x33',
  D_START = '0x20',
  D_END = '0x21'
}

export enum DpgSegmentationType {
  BREAK = '0x22',
  PROVIDER_AD = '0x30',
}
