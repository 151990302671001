import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export function iso8601DurationToMs (iso8601Duration: string): number {
	return dayjs.duration(iso8601Duration).asMilliseconds();
}

/**
 * @param {Number} seconds
 * @returns {string} – ISO8601 duration string
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 */
export function secondsToIso8601Duration(seconds: number): string {
	return dayjs.duration(seconds, 's').toISOString();
}