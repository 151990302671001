//Libs:
import { extern } from '@shakaPlayer';
import { ILogger } from 'js-logger';
import { KeySystemToProtectionSystemType, ProtectionSystemType } from '../../../consts/drm';
import { StreamingProtocol } from '../../../consts/protocol';
import {
  parseFromString,
  removeAudio,
  serializeToString,
  setMinimumUpdatePeriod,
} from '../../../utils/manifest';
import { DashManifestParser } from '../../../utils/manifestParser';
import { toUtf8 } from '../../../utils/string';
import { secondsToIso8601Duration } from '../../../utils/date';
import { DrmConfiguration } from '../../externalPlayer';
import ShakaExternalPlayer, { ManifestConfig, basicVideoCapabilities } from './shakaExternalPlayer';

const SUBTITLE_BACKGROUND_VOD = {
  background: 'background',
  outlineText: 'outlineText',
};

const SUBTITLE_FONT = {
  mayberry: 'Mayberry'
};

const defaultDrmPriority = [
  ProtectionSystemType.Widevine,
  ProtectionSystemType.Playready,
];

export default class ShakaDashExternalPlayer extends ShakaExternalPlayer {
  protected drmPriority: ProtectionSystemType[] = defaultDrmPriority;
  protected readonly mimeType = 'application/dash+xml';
  public readonly protocol = StreamingProtocol.MpegDash;
  private manifestParser: DashManifestParser | null = null;
  private currentManifestType: string | null = null;

  public setStyles(
    isVod: boolean,
    subtitleBackgroundVOD: string | undefined,
    subtitleFontVOD: string | undefined,
    isFontLoaded: boolean,
    logger: ILogger
  ): void {
    if (!isVod) {
      return;
    }

    const style = window.document.createElement('style');

    const videoContainer = document.querySelector('video');
    const videoHeight = videoContainer?.clientHeight;
    const fontSize = videoHeight ? `${videoHeight / 17}px` : '';

    style.textContent = getCaptionStyles(fontSize);
    window.document.head.appendChild(style);

    subtitleBackgroundVOD && window.document.body.classList.add(subtitleBackgroundVOD);

    if (subtitleFontVOD === SUBTITLE_FONT.mayberry && !isFontLoaded) {
      loadMayberryFont(logger);
      const fontStyle = window.document.createElement('style');

      fontStyle.textContent = `
        .shaka-text-container span{
            font-family: ${SUBTITLE_FONT.mayberry} !important;
        }
        `;

      window.document.head.appendChild(fontStyle);
    }
  }

  protected configureManifestParser(): void {
    this.manifestParser = new DashManifestParser({
      parseScte: this.parseScte,
      parserType: this.adBreakType,
      parsePlacement: this.parsePlacement,
      parseOpportunity: this.parseOpportunity,
    });
  }

  private processAudioTracks(manifest: Document): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const shouldRemoveAudioTracks = window.localStorage.getItem('flutter.isAudioRemovedEnabled');

    if (!shouldRemoveAudioTracks || !JSON.parse(shouldRemoveAudioTracks)) {
      return;
    }

    removeAudio(manifest);
  }

  private processMinimumUpdatePeriod(manifest: Document, minimumUpdatePeriod: number): void {
    if (minimumUpdatePeriod <= 0) {
      return;
    }

    const updateDuration = secondsToIso8601Duration(minimumUpdatePeriod);

    setMinimumUpdatePeriod(manifest, updateDuration);
  }

  protected processManifest(response: extern.Response, manifest: string, config: ManifestConfig): void {
    const parsedManifest = parseFromString(manifest);
    const { minimumUpdatePeriod } = config;

    // Performing modifications on parsed manifest:
    this.processAudioTracks(parsedManifest);
    this.processMinimumUpdatePeriod(parsedManifest, minimumUpdatePeriod);

    const updatedManifestString = serializeToString(parsedManifest);

    response.data = toUtf8(updatedManifestString);
  }

  protected parseManifest(manifest: string): void {
    if (!this.manifestParser) {
      return;
    }

    const manifestData = this.manifestParser.parseManifest(manifest, {
      shouldParsePreRoll: this.shouldParsePreRoll,
    });

    if (this.currentManifestType !== manifestData.manifestType) {
      this.currentManifestType = manifestData.manifestType;

      this.dispatchManifestTypeChangedEvent();
    }

    this.audioDescriptionStandartWayOfSignaling = manifestData.audioDescriptionTracks;
    this.hardOfHearingStandartWayOfSignaling = manifestData.subtitleHardOfHearingTracks;
    this.playerAdBreaks = manifestData.playerAdBreaks;
    this.playerScteSegments = manifestData.playerScteSegments;
    this.audioManifestTracks = manifestData.audioManifestTracks;
    this.subtitlesManifestTracks = manifestData.subtitlesManifestTracks;
  }

  protected isAudioDescription(track: extern.Track): boolean {
    const { originalAudioId } = track;

    return originalAudioId ? this.audioDescriptionStandartWayOfSignaling.includes(originalAudioId) : false;
  }

  protected isHardOfHearing(track: extern.Track): boolean {
    const { originalTextId } = track;

    if (!originalTextId) {
      return false;
    }

    const originalTextIdList = originalTextId.split(',');
    return originalTextIdList[0] ? this.hardOfHearingStandartWayOfSignaling.includes(originalTextIdList[0]) : false;
  }

  protected checkVariant(track: extern.Track, language: string, _role: string, usedIds: number[]): boolean {
    const isTrackTheSame = track.language === language && track.audioId && !usedIds.includes(track.audioId);

    return Boolean(isTrackTheSame);
  }

  protected getSeekableDuration(): number | undefined {
    return this.shakaPlayer.getManifest()?.presentationTimeline.getSeekRangeEnd();
  }

  protected getManifestType(): string | null {
    return this.currentManifestType;
  }

  public setDrmConfiguration(drmConfiguration: DrmConfiguration): void {
    const { keySystemPriority, forceKeySystem } = drmConfiguration;

    if (forceKeySystem) {
      this.forceKeySystem = forceKeySystem;
    }

    if (keySystemPriority && keySystemPriority.length) {
      this.drmPriority = keySystemPriority.map((drm) => KeySystemToProtectionSystemType[drm]);
    }
  }

  protected async isPersistentStateRequiredSupported(keySystem: ProtectionSystemType): Promise<boolean> {
    try {
      const basicConfig = {
        initDataTypes: ['cenc'],
        persistentState: 'required',
        videoCapabilities: basicVideoCapabilities,
      };

      const access = await navigator.requestMediaKeySystemAccess(
        keySystem, [basicConfig as MediaKeySystemConfiguration]
      );

      return access.getConfiguration().persistentState === 'required';
    } catch (e) {
      return false;
    }
  }

  public clear(): void {
    super.clear();

    this.playerAdBreaks = [];
    this.currentManifestType = null;
  }
}

function loadMayberryFont(logger: ILogger) {
  const font = new FontFace(
    'Mayberry',
    'url(/packages/horizon_player/web/assets/fonts/mayberry_pro.ttf)',
  );

  font
    .load()
    .then((loaded_face) => {
      window.document.fonts.add(loaded_face);
    })
    .catch((error) => {
      logger.debug(error);

    });
}

function getCaptionStyles(fontSize: string) {
  return `
  .text-base-subtitles {
      bottom: 10%;
      position: absolute;
      z-index: 1;
  }

  .${SUBTITLE_BACKGROUND_VOD.outlineText} .shaka-text-container span::before {
    content: attr(data-text);
    position: absolute;
    -webkit-text-stroke: 5px black;
    z-index: -1;
  }

  .shaka-text-container span {
      transform: rotate(360deg);
      background-color: transparent;
      ${fontSize ? `fontSize:${fontSize} !important` : ''};
      line-height: 1.32 !important;
  }

  .${SUBTITLE_BACKGROUND_VOD.background} .text-content {
      background-color: rgb(0,0,0) !important;
      padding: 5px 15px !important;
  }
  `;
}