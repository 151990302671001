import { extern } from '@shakaPlayer';
import { eqProps, findLast } from 'ramda';
import Segment from './segment';

const MAX_RECOVERIES_NUMBER = 3;
const MAX_CACHE_LENGTH = 32;

type Predicate = (val: Segment) => boolean;

class SegmentsRecovery {
    private segmentsCache: Array<Segment>;
    private corruptedSegment?: Segment | null;
    private recoveries: number;

    constructor() {
        this.segmentsCache = [];
        this.corruptedSegment = null;
        this.recoveries = 0;
    }

    private invalidateOldSegments(): void {
        if (this.segmentsCache.length > MAX_CACHE_LENGTH) {
            this.segmentsCache = this.segmentsCache.slice(MAX_CACHE_LENGTH / 2);
        }
    }

    private isSegmentCorrupted(segment: Segment): boolean {
        return !!this.corruptedSegment && this.corruptedSegment.isEqual(segment);
    }

    public isRecoverable(): boolean {
        return this.recoveries < MAX_RECOVERIES_NUMBER;
    }

    private findLastInCache(predicate: Predicate): Segment | null {
        const segment = findLast(predicate, this.segmentsCache);

        if (!segment) {
            return null;
        }

        return segment;
    }


    private sameTypePredicate = (segment: Segment) => (item: Segment) => {
        return (
            (!item.isEqual(segment) || !item.isInitChunk())
            && segment.type === item.type
        );
    };

    private sameTypeAndQualityPredicate = (segment: Segment) => (item: Segment) => {
        return this.sameTypePredicate(segment)(item) && eqProps('quality', segment, item);
    };

    private getSegmentReplacement(segment: Segment): Segment {
        const sameTypeAndQuality = this.findLastInCache(
            this.sameTypeAndQualityPredicate(segment)
        );

        const sameType = this.findLastInCache(this.sameTypePredicate(segment));

        return sameTypeAndQuality || sameType || segment;
    }

    public processNewSegment(response: extern.Response): extern.Response {
        const segment = new Segment(response);

        this.emulateCorruptedSegment(response);

        if (this.isSegmentCorrupted(segment)) {
            const replacement = this.getSegmentReplacement(segment);

            response.data = replacement.data;

            this.corruptedSegment = null;
        } else {
            this.segmentsCache.push(segment);
        }

        this.invalidateOldSegments();

        return response;
    }

    public processError(): void {
        this.recoveries++;

        this.corruptedSegment = this.segmentsCache[
            this.segmentsCache.length - 1
        ];
    }

    public clear(): void {
        this.recoveries = 0;
        this.segmentsCache = [];
        this.corruptedSegment = null;
    }

    private emulateCorruptedSegment(response: extern.Response): void {
        if (!window.__corrupted__) {
            return;
        }

        const segment = new Segment(response);

        if (window.__chunks__ === undefined) {
            window.__chunks__ = 0;
        }

        window.__chunks__++;

        if (
            this.isSegmentCorrupted(segment)
            || window.__chunks__ % window.__corrupted__ === 0
        ) {
            response.data = new ArrayBuffer(255);

            window.__corrupted__ = 999999;
        }
    }
}

export default SegmentsRecovery;
