import { isEmpty } from 'ramda';
import { STORAGE_NAME } from './consts';
import { LicenseStorage, PersistentLicenseStorageOptions, SessionData } from './types';

class PersistentLicenseStorage implements LicenseStorage {
    private readonly storage: Storage;

    constructor(opt: PersistentLicenseStorageOptions) {
        this.storage = opt.storage;
    }

    public getStorage(): Record<string, SessionData> | null {
        const data = this.parse(
            this.storage.getItem(STORAGE_NAME)
        );

        if (!data || isEmpty(data)) {
            return null;
        }

        return data as Record<string, SessionData>;
	}

	public setItem(value: Record<string, unknown>): void {
        this.storage.setItem(
            STORAGE_NAME,
            this.serialize(value)
        );
	}

	public clear(): void {
		this.storage.setItem(
            STORAGE_NAME,
            this.serialize({})
        );
	}

    private serialize(data: Record<string, unknown>): string {
        return JSON.stringify(data);
    }

    private parse(data: string | null): unknown {
        if (!data) {
            return;
        }

        return JSON.parse(data);
    }
}

export default PersistentLicenseStorage;
