import { PlayerScteSegment } from '../../../../shared';

export type DataInfo = {
    startTime?: number,
    endTime?: number,
    duration?: number,
    typeId?: string,
    id?: number,
};

export type CueInfo = {
    startTime: number,
    endTime?: number,
    duration?: number,
    typeId: string,
    type: string,
    id: number,
};

export type Segment = {
    start: CueInfo,
    end?: CueInfo,
};

export const sortSegmentsByStartTime = (segments: Segment[]): Segment[] =>
    segments.sort((segment1: Segment, segment2: Segment): number =>
        segment1.start.startTime - segment2.start.startTime
    );

const formatValue = (str: string): string => str.slice(1, -1);

export const parseData = (data: string): DataInfo | null => {
    const utf8String = atob(data);
    const chunks = utf8String.split(',');

    if (chunks.length === 1) {
        return null;
    }

    return chunks.reduce((acc: DataInfo, chunk: string) => {
        const [key, value] = chunk.split('=');

        if (!key || !value) {
            return acc;
        }

        if (key === 'START-DATE') {
            const startTime = formatValue(value);

            acc.startTime = new Date(startTime).getTime();
        }

        if (key === 'END-DATE') {
            const endTime = formatValue(value);

            acc.endTime = new Date(endTime).getTime();
        }

        if (key === 'DURATION') {
            acc.duration = Number(value);
        }

        if (key === 'X-SCTE35-SEG-TYPE-ID') {
            acc.typeId = value.slice(1, -1);
        }

        if (key === 'ID') {
            acc.id = Number(formatValue(value));
        }
      
        return acc;
    }, {});
};

export const getSegmentEndTime = (segment: Segment, nextSegment: Segment | null): number => {
    if (segment.end) {
        const { endTime } = segment.end;
    
        if (endTime) {
            return endTime;
        }
      
        const { duration } = segment.end;
    
        const { startTime } = segment.start;

        if (!endTime && duration) {
            return startTime + duration;
        }
    }
  
    if (!nextSegment) {
        return Infinity;
    }

    const { start: { startTime } } = nextSegment;
  
    return startTime;
};

export const createPlayerScteSegments = (segments: Segment[]): PlayerScteSegment[] =>
    segments.reduce((acc: PlayerScteSegment[], segment: Segment, index) => {
        const { startTime } = segment.start;
        const nextSegment = segments[index + 1] ?? null;
        const endTime = getSegmentEndTime(segment, nextSegment);

        const scteSegment = {
            startTime,
            endTime,
            segmentationTypeId: segment.start.typeId,
        };
        
        return [...acc, scteSegment];
}, []);