import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { ParsePlacement, PlayerAdBreaksInfo } from '../../../../shared';
import { getAllManifestPeriods } from '../../../manifest';
import InsertedAdBreaksParser from './insertedAdBreaksParser';


export interface FfwdDashAdBreaksParserDeps {
    readonly parsePlacement: ParsePlacement;
}

/**
 * Ffwd ad breaks parsing strategy for DASH Manifest
 * 
 * see Opportunity - DASH and Placement - DASH in:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads
 */
export default class FfwdDashAdBreaksParser extends InsertedAdBreaksParser {
    static AD_TYPE = AdBreaksParsingType.ffwd;

    protected get adBreaksType(): AdBreaksParsingType {
        return FfwdDashAdBreaksParser.AD_TYPE;
    }

    public parseAdBreaks(manifest: Document): PlayerAdBreaksInfo {
        const periodNodes = getAllManifestPeriods(manifest);

        if (!periodNodes) {
            return {
                adBreaks: []
            };
        }

        const adBreaks = this.parseInsertedAdBreaks(periodNodes);

        return { adBreaks };
    }
}