import { PlayerAdBreak } from '../shared';
import { PlayerEventType } from './eventTypes';
import { PlayerEvent } from './playerEvent';

export class PlayerDurationUpdateEvent extends PlayerEvent {
    constructor(public readonly duration: number) {
        super(PlayerEventType.DurationUpdate);
    }
}

export class PlayerManifestTypeUpdateEvent extends PlayerEvent {
    constructor(public readonly manifestType: string) {
        super(PlayerEventType.ManifestTypeUpdate);
    }
}

export class PlayerVolumeUpdateEvent extends PlayerEvent {
    constructor(public readonly volume: number) {
        super(PlayerEventType.VolumeUpdate);
    }
}

export class PlayerPositionUpdateEvent extends PlayerEvent {
    constructor(public readonly position: number) {
        super(PlayerEventType.PositionUpdate);
    }
}

export class PlayerBandwidthUpdateEvent extends PlayerEvent {
    constructor(public readonly bandwidth: number) {
        super(PlayerEventType.BandwidthUpdate);
    }
}

export class PlayerBufferLengthUpdateEvent extends PlayerEvent {
    constructor(public readonly bufferLengthh: number) {
        super(PlayerEventType.BufferLengthUpdate);
    }
}

export class PlayerFrameRateUpdateEvent extends PlayerEvent {
    constructor(public readonly fps: number) {
        super(PlayerEventType.FrameRateUpdate);
    }
}

export class PlayerSeekUpdateEvent extends PlayerEvent {
    constructor(public readonly isSeeking: boolean) {
        super(PlayerEventType.SeekUpdate);
    }
}

export class PlayerAdBreaksUpdateEvent extends PlayerEvent {
    constructor(public readonly adBreaks: PlayerAdBreak[]) {
        super(PlayerEventType.AdBreaksUpdate);
    }
}

export class PlayerLogUpdateEvent extends PlayerEvent {
    constructor(public readonly log: string) {
        super(PlayerEventType.PlayerLogUpdateEvent);
    }
}

export class PlayerReviewBufferUpdateEvent extends PlayerEvent {
    constructor(public readonly reviewBuffer: number) {
        super(PlayerEventType.ReviewBufferUpdateEvent);
    }
}

export class PlayerScteSegmentsUpdateEvent extends PlayerEvent {
    constructor(public readonly scteSegments: PlayerAdBreak[]) {
        super(PlayerEventType.ScteSegmentsUpdate);
    }
}
export class PlayerRecoveringEvent extends PlayerEvent {
    constructor(public readonly isRecovering: boolean) {
        super(PlayerEventType.RecoveringEvent);
    }
}

export class PlayerDiagnosticInfoUpdateEvent extends PlayerEvent {
    constructor(public readonly diagnosticPlayerInfo: string) {
        super(PlayerEventType.DiagnosticInfoUpdate);
    }
}
