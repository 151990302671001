export enum PlayerEventType {
    Error = 'PlayerError',
    AdError = 'PlayerAdError',
    PositionUpdate = 'PlayerTimeUpdate',
    VolumeUpdate = 'PlayerVolumeUpdate',
    DurationUpdate = 'PlayerDurationChanged',
    StateUpdate = 'PlayerStateUpdate',
    BandwidthUpdate = 'PlayerBandwidthUpdate',
    TextTracksUpdate = 'PlayerTextTracksUpdate',
    AudioTracksUpdate = 'PlayerAudioTracksUpdate',
    SeekUpdate = 'PlayerSeekUpdate',
    AdBreaksUpdate = 'PlayerAdBreaksUpdate',
    ScteSegmentsUpdate = 'PlayerScteSegmentsUpdate',
    PictureInPictureStateUpdate = 'PictureInPictureStateUpdate',
    PlayerLogUpdateEvent = 'PlayerLogUpdateEvent',
    ReviewBufferUpdateEvent = 'ReviewBufferUpdateEvent',
    RecoveringEvent = 'PlayerRecoveringEvent',
    ManifestTypeUpdate = 'PlayerManifestTypeUpdate',
    BufferLengthUpdate = 'PlayernBufferLengthUpdate',
    FrameRateUpdate = 'PlayerFrameRateUpdate',
    DiagnosticInfoUpdate = 'DiagnosticInfoUpdate',
}
