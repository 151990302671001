import { partition } from 'ramda';
import { ManifestType } from '../../consts/manifestType';
import { APPLICATION_MIME_TYPE, AUDIO_MIME_TYPE } from '../../consts/mimeTypes';
import ManifestTracks from '../../externalPlayer/implementations/shakaExternalPlayer/models/manifestTracks';
import { PlayerAdBreak } from '../../shared';
import { iso8601DurationToMs } from '../date';

const SCHEME_ID_URI_2007 = 'urn:tva:metadata:cs:AudioPurposeCS:2007';
const SCHEME_ID_URI_2011 = 'urn:mpeg:dash:role:2011';
const ACCESSIBILITY_VALUES = {
  one: '1',
  two: '2',
  caption: 'caption',
  description: 'description',
  sign: 'sign'
};

export const ROLE_VALUES = {
  alternate: 'alternate',
  commentary: 'commentary'
};

const PERIOD_SELECTOR = 'Period';
const EVENT_STREAM_SELECTOR = 'EventStream';
const EVENT_SELECTOR = 'Event';
const MPD_SELECTOR = 'MPD';


const APP_SELECTOR = `[${APPLICATION_MIME_TYPE}]`;
const AUDIO_SELECTOR = `[${AUDIO_MIME_TYPE}]`;

export interface PlayerAdBreakPeriods {
  period: Element;
  nextPeriod: Element | null;
}

export interface PeriodInfo {
  period: Element;
  start: number;
  duration: number | null;
  isLastPeriod: boolean;
  end: number;
}

const SECOND_IN_MS = 1000;

export interface PeriodInfoWithSegmentationTypeId extends PeriodInfo{
  id: string;
}

export const parseFromString = (manifest: string): Document => new DOMParser().parseFromString(manifest, 'text/xml');

export const serializeToString = (node: Node): string => new XMLSerializer().serializeToString(node);

const findElementByTag = (tagName: string, elements: HTMLCollection): Element|undefined => [...elements].find((child: Element) => {
  return child.tagName === tagName;
});

const getNodes = (manifest: Document | Element, selector: string): NodeListOf<Element> =>
  manifest.querySelectorAll(selector);

const getNode = (manifest: Document, selector: string): Element | null =>
  manifest.querySelector(selector);

const getMpdNode = (manifest: Document) => getNode(manifest, MPD_SELECTOR);

const getMpdAttribute = (attrName: string) => (manifest: Document): string | null =>
  getMpdNode(manifest)?.getAttribute(attrName) || null;

const setMpdAttribute = (attrName: string) => (manifest: Document, attrValue: string): void => {
  getMpdNode(manifest)?.setAttribute(attrName, attrValue);
};

/**
 * @param {Document} manifest
 * @param {string} minimumUpdatePeriod – ISO8601 duration string
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 * @see https://dashif.org/docs/DASH-IF-IOP-v4.2-clean.htm#_Toc511040769
 */
export const setMinimumUpdatePeriod = setMpdAttribute('minimumUpdatePeriod');

/**
 * MPD@mediaPresentationDuration
 * mandatory (for the considered use cases)
 * provides the duration of the Media Presentation.
 * @see https://dashif.org/docs/DASH-IF-IOP-v4.2-clean.htm#_Toc511040769
 */
const getMediaPresentationDuration = getMpdAttribute('mediaPresentationDuration');

export const getManifestDuration = (manifest: Document): number => {
  const mediaPresentationDuration = getMediaPresentationDuration(manifest);

  if (!mediaPresentationDuration) {
    return 0;
  }

  return iso8601DurationToMs(mediaPresentationDuration);
};

export const getAdaptationSetsWithAudioDescription = (adaptationNodes: NodeListOf<Element>): Element[] => {
  return [...adaptationNodes].filter((node: Element)=> {
    const children = node.children;

    const accessibilityTag = findElementByTag('Accessibility', children);

    const roleTag = findElementByTag('Role', children);

    if (!accessibilityTag || !roleTag) {
        return false;
    }

    const accessibilitySchemeIdUri = accessibilityTag.getAttribute('schemeIdUri');
    const roleSchemeIdUri =roleTag.getAttribute('schemeIdUri');
    const accessibilityValue = accessibilityTag.getAttribute('value');
    const roleValue = roleTag.getAttribute('value');

    if (accessibilitySchemeIdUri===SCHEME_ID_URI_2007
        && accessibilityValue === ACCESSIBILITY_VALUES.one
        && roleSchemeIdUri === SCHEME_ID_URI_2011
        && (roleValue === ROLE_VALUES.alternate || roleValue === ROLE_VALUES.commentary)){
            return true;
        }

    if (accessibilitySchemeIdUri===SCHEME_ID_URI_2011
            && accessibilityValue === ACCESSIBILITY_VALUES.description
            && roleSchemeIdUri === SCHEME_ID_URI_2011
            && (roleValue === ROLE_VALUES.alternate || roleValue === ROLE_VALUES.commentary)){
                return true;
            }

    return false;
  });
};

export const createAccessibilityTracksList = (adaptations: Element[]): string[] => {
  return [...adaptations].map((adaptation)=> {
    const children = adaptation.children;

    const representation = findElementByTag('Representation', children);
    const audioId = representation?.getAttribute('id');

    return audioId || '';
  });
};

export const getAdaptationSetsWithSubtitleHardOfHearingFeature =(adaptationNodes: NodeListOf<Element>): Element[] => {
  return [...adaptationNodes].filter((node: Element)=> {
    const children = node.children;

    const accessibilityTag = findElementByTag('Accessibility', children);

    if (!accessibilityTag) {
      return false;
    }

    const accessibilitySchemeIdUri = accessibilityTag.getAttribute('schemeIdUri');
    const accessibilityValue = accessibilityTag.getAttribute('value');

    if (accessibilitySchemeIdUri === SCHEME_ID_URI_2007 && accessibilityValue === ACCESSIBILITY_VALUES.two
    ||(accessibilitySchemeIdUri === SCHEME_ID_URI_2011 &&
    (accessibilityValue === ACCESSIBILITY_VALUES.caption || accessibilityValue === ACCESSIBILITY_VALUES.sign))
    ) {
      return true;
    }

    return false;
  });
};

export const getEventStreamNode = (element: Element): Element | null => element.querySelector(EVENT_STREAM_SELECTOR);

export const getEventStreamNodes = (element: Element): Element[] => [...element.querySelectorAll(EVENT_STREAM_SELECTOR)];

export const getEventNode = (element: Element): Element | null => element.querySelector(EVENT_SELECTOR);

export const getEventNodes = (element: Element): Element[] => [...element.querySelectorAll(EVENT_SELECTOR)];

export const getAllPeriodNodes = (manifest: Document): NodeListOf<Element> => getNodes(manifest, PERIOD_SELECTOR);

export const getNodeByTagName = (element: Element, tag: string): Element | null => {
  function allDescendants(node: Element): Element | null {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];

      if (!child) {
        continue;
      }

      if (child?.tagName?.toUpperCase() === tag.toUpperCase()) {
        return child;
      }

      const element = allDescendants(child);

      if (element) {
        return element;
      }
    }

    return null;
  }

  return allDescendants(element) || null;
};

export const getFirstPeriodNode = (manifest: Document): Element | null => getNode(manifest, PERIOD_SELECTOR);

export const getStart = (element: Element, defaultValue = 0): number => {
  const startDuration = element.getAttribute('start');

  return startDuration ? iso8601DurationToMs(startDuration) : defaultValue;
};

export const getPeriodDuration = (element: Element): number | null => {
  const duration = element.getAttribute('duration');

  return duration ? iso8601DurationToMs(duration) : null;
};

export const getRootPeriodStart = (manifest: Document): number => {
  const firstPeriod = getFirstPeriodNode(manifest);

  if (!firstPeriod) {
    return 0;
  }

  return getStart(firstPeriod);
};

const getNodesList = (element: Element, selector: string): Element[] => [...getNodes(element, selector)];

/**
 * Reads and parses the periods from the manifest.
 * Constructs Period Info with timeline information relative to the playback start.
 *
 * The Period extends until the Period.start of the next Period, or
 * until the end of the Media Presentation in the case of the last
 * Period.
 *
 * Only use the @duration in the MPD if we can't calculate it.  We should
 * favor the @start of the following Period.  This ensures that there
 * aren't gaps between Periods.
 *
 * For more info please check dash_parser in the shaka_player lib implementation.
 */
export const getAllManifestPeriods = (manifest: Document): PeriodInfo[] => {
  const periodsNodes = [ ...getAllPeriodNodes(manifest)];
  const presentationDuration = getManifestDuration(manifest);
  const rootPeriodStart = getRootPeriodStart(manifest);

  let prevEnd = 0;

  return periodsNodes.reduce((acc: PeriodInfo[], period: Element, id: number, list: Element[]): PeriodInfo[] => {
    const nextPeriod = list[id + 1];

    const start = getStart(period, prevEnd) - rootPeriodStart;
    const givenDuration = getPeriodDuration(period);

    let periodDuration = null;

    if (nextPeriod) {
      const nextStart = getStart(nextPeriod) - rootPeriodStart;

      if (nextStart) {
        periodDuration = nextStart - start;
      }
    } else if (presentationDuration) {
      periodDuration = presentationDuration - start;
    }

    if (!periodDuration) {
      periodDuration = givenDuration;
    }

    if (periodDuration != null) {
      prevEnd = start + periodDuration;
    }

    const info = {
      period,
      start: start,
      duration: periodDuration,
      isLastPeriod: !nextPeriod,
      end: start + (periodDuration || SECOND_IN_MS),
    };

    return [...acc, info];
  }, []);
};

const removeManifestElements = (parsedManifest: Document, querySelector: string) => {
  const nodeList = parsedManifest.querySelectorAll(querySelector);

  nodeList.forEach((node) => node.remove());
};

export const removeAudio = (manifest: Document): void => {
  removeManifestElements(manifest, '[mimeType="audio/mp4"]');
};

const getLangsFromAdaptationSets = (periods: PeriodInfo[], mimeTypeSelector: string): string[] => {
  const languageSet = periods
    .map((info) => info.period)
    .reduce((acc: Set<string>, period: Element) => {
      getNodesList(period, mimeTypeSelector).forEach((adaptationSet) => {
        const lang = adaptationSet.getAttribute('lang');

        if (lang) {
          acc.add(lang);
        }
      });

      return acc;
    }, new Set());

    return Array.from(languageSet);
};

const isAdBreakPeriod = (period: PeriodInfo, adBreaks: PlayerAdBreak[]): boolean => {
  const periodStart = period.start;
  const periodEnd = period.start + (period.duration || 0);

  return adBreaks.some(
    (ad) => periodStart >= ad.startTime && periodEnd <= ad.endTime
  );
};

const getManifestTracks = (mimeTypeSelector: string) => (manifest: Document, adBreaks: PlayerAdBreak[]): ManifestTracks => {
  const periods = getAllManifestPeriods(manifest);

  const [ adBreaksPeriods, mainContentPeriods ] = partition(
    (period) => isAdBreakPeriod(period, adBreaks),
    periods
  );

  const adBreaksTracks = getLangsFromAdaptationSets(adBreaksPeriods, mimeTypeSelector);
  const mainContentTracks = getLangsFromAdaptationSets(mainContentPeriods, mimeTypeSelector);

  return new ManifestTracks(adBreaksTracks, mainContentTracks);
};

export const getManifestAudioTracks = getManifestTracks(AUDIO_SELECTOR);
export const getManifestSubtitlesTracks = getManifestTracks(APP_SELECTOR);

export const mapManifestType = (manifestType: string | null): string | null => {
  switch (manifestType) {
    case 'dynamic':
      return ManifestType.Dynamic;
    case 'static':
      return ManifestType.Static;
    default:
      return null;
  }
};

export const getManifestType = (manifest: Document): string | null => {
  const mpd = manifest.querySelector('MPD');

  if (!mpd) {
    return null;
  }

  const type = mpd.getAttribute('type');

  return mapManifestType(type);
};
