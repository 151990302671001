import { PlayerEventType } from './eventTypes';
import { PlayerEvent } from './playerEvent';

export enum PlayerState {
    Playing = 'playing',
    Paused = 'paused',
    Buffering = 'buffering',
    Stopped = 'stopped',
}

export class PlayerStateUpdateEvent extends PlayerEvent {
    public readonly state: PlayerState = PlayerState.Stopped;

    constructor() {
        super(PlayerEventType.StateUpdate);
    }
}

export class PausedStatePlayerEvent extends PlayerStateUpdateEvent {
    state = PlayerState.Paused;
}

export class PlayingStatePlayerEvent extends PlayerStateUpdateEvent {
    state = PlayerState.Playing;
}

export class BufferingStatePlayerEvent extends PlayerStateUpdateEvent {
    state = PlayerState.Buffering;
}

export class StoppedStatePlayerEvent extends PlayerStateUpdateEvent {
    state = PlayerState.Stopped;
}
