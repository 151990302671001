/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { both, complement, is, isEmpty, isNil, of, path, prop, when } from 'ramda';
import { util } from '../../lib/shaka-player';

export const isLicenseError = (error: util.Error): boolean => {
    return !isNil(error.data)
        && (error.data instanceof Array)
        && (error.data[0]?.data instanceof Array ?? false)
        && (error.data[0].data.length >= 2)
        && (error.data[0].data[0] instanceof String)
        && (error.data[0].data[0].indexOf('license/eme') >= 0);
};

const transformErrorResponseBody = when(
	both(complement(Array.isArray), is(Object)),
	of
);

const isNotString = complement(is(String));

const getResponseBody = (responseBodyString: string | undefined): Array<Record<string, unknown>> => {
    if (
        isNil(responseBodyString)
        || isNotString(responseBodyString)
        || isEmpty(responseBodyString)
    ) {
        return [];
    }

    try {
        const responseBody = JSON.parse(responseBodyString) as unknown;

        return transformErrorResponseBody(responseBody) as Array<Record<string, unknown>>;
    } catch (e) {
        return [];
    }
};

const getErrorResponseCode = (error: util.Error): string | undefined => {
    if (
        !(error.data instanceof Array) ||
        !(error.data[0].data instanceof Array)
    ) {
        return;
    }

    const responseBodyString = error.data[0].data[2] as string;
    const responseBody = getResponseBody(responseBodyString);

    const [errorData = {}] = responseBody;

    return prop('code', errorData) as string | undefined;
};

export const isLicenseServerFatalError = (error: util.Error): boolean => {
    const httpStatusCode = path(['0', 'data', '1'], error.data);
    const responseCode = getErrorResponseCode(error);

    return !(httpStatusCode === 400 && responseCode === 'license');
};
