import { Player as ShakaPlayer } from '@shakaPlayer';
import IGapJumpingLockService from './igap_jumping_lock_service';

export default class GapJumpingLockService implements IGapJumpingLockService {
    private readonly shakaPlayer: ShakaPlayer;

    private _smallGapLimit = 0;
    private _jumpLargeGaps = false;
    private _isProcessingInProgress = false;
    private _isSeekFinished = true;
    private _currentTimeOnSeekEnd = 0;

    constructor(shakaPlayer: ShakaPlayer) {
        this.shakaPlayer = shakaPlayer;
    }

    public unload(): void {
        this._restoreConfiguration();
    }

    public onSeekStart(): void {
        if (this._isProcessingInProgress) {
            return;
        }

        this._isProcessingInProgress = true;
        this._isSeekFinished = false;

        this._smallGapLimit = this.shakaPlayer.getConfiguration().streaming.smallGapLimit;
        this._jumpLargeGaps = this.shakaPlayer.getConfiguration().streaming.jumpLargeGaps;

        this.shakaPlayer.configure({
            streaming: {
                jumpLargeGaps: false,
                smallGapLimit: Number.MIN_VALUE,
            },
        });
    }

    public onSeekEnd(): void {
        const mediaElement = this.shakaPlayer.getMediaElement();

        if (mediaElement?.ended) {
            this._restoreConfiguration();

            return;
        }

        this._isSeekFinished = true;

        this._currentTimeOnSeekEnd = mediaElement?.currentTime ?? 0;
    }

    public onVideotimeUpdate(): void {
        if (this._isProcessingInProgress && this._isSeekFinished) {
            const currentTime = this.shakaPlayer.getMediaElement()?.currentTime ?? 0;

            if (currentTime > this._currentTimeOnSeekEnd) {
                this._restoreConfiguration();
            }
        }
    }

    private _restoreConfiguration(): void {
        if (this._isProcessingInProgress) {
            this.shakaPlayer.configure({
                streaming: {
                    jumpLargeGaps: this._jumpLargeGaps,
                    smallGapLimit: this._smallGapLimit,
                },
            });

            this._isProcessingInProgress = false;
            this._isSeekFinished = true;
        }
    }
}