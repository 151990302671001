import {
  ParseOpportunity,
  ParsePlacement,
  ParseScte,
  PlayerAdBreak,
  PlayerAdBreaksInfo,
  PlayerAdBreaksParserOptions
} from '../../../shared';
import { getBasicAdBreaksRelativeTimeInfo } from '../../hls';
import DpgAvadAdBreakParser from './dpgAvadAdBreakParser/dpgAvadAdBreakParser';
import FfwdAdBreakParserService, { FfwdHlsAdBreakParser } from './ffwdAdBreaksParser/ffwdAdBreaksParser';
import Gt12CHAdBreaksParserService from './gt12AdBreaksParser/gt12ChAvadAdBreaksParser';
import { LinearAvadHlsAdBreaksParser } from './linearAvadAdBreakParser/linearAvadAdBreakParser';
import VodAdBreakParserService, { VodHlsAdBreakParser } from './vodAdBreaksParser/vodAdBreaksParser';

interface ManifestParser {
  parseBasicAdBreaks(manifest: string): PlayerAdBreak[];
  parseVodAdBreaks(textTrackList?: TextTrackList): PlayerAdBreak[];
}

export interface HlsManifestParserDeps {
  readonly parseOpportunity: ParseOpportunity;
  readonly parsePlacement: ParsePlacement;
  readonly parseScte: ParseScte;
  readonly ignoredScteTypes: number[];
}

export default class HlsManifestParser implements ManifestParser {
  private readonly vodAdBreakParser: VodHlsAdBreakParser;
  private readonly gt12CHAdBreaksParser: LinearAvadHlsAdBreaksParser;
  private readonly ffwdAdBreakParser: FfwdHlsAdBreakParser;
  private readonly dpgAdBreakParser: LinearAvadHlsAdBreaksParser;
  private readonly parseScte: ParseScte;
  private readonly ignoredScteTypes: number[];


  constructor(deps: HlsManifestParserDeps) {
    this.vodAdBreakParser = new VodAdBreakParserService({
      parseOpportunity: deps.parseOpportunity,
      parsePlacement: deps.parsePlacement
    });

    this.gt12CHAdBreaksParser = new Gt12CHAdBreaksParserService({
      parseOpportunity: deps.parseOpportunity,
      parsePlacement: deps.parsePlacement
    });

    this.ffwdAdBreakParser = new FfwdAdBreakParserService({
      parseOpportunity: deps.parseOpportunity,
      parsePlacement: deps.parsePlacement
    });

    this.dpgAdBreakParser = new DpgAvadAdBreakParser({
      parseOpportunity: deps.parseOpportunity,
      parsePlacement: deps.parsePlacement
    });

    this.parseScte = deps.parseScte;
    this.ignoredScteTypes = deps.ignoredScteTypes;
  }

  parseBasicAdBreaks(manifest: string): PlayerAdBreak[] {
    return getBasicAdBreaksRelativeTimeInfo({
      manifest,
      ignoredScteTypes: this.ignoredScteTypes,
      parseScte: this.parseScte,
    });
  }

  parseVodAdBreaks(textTrackList?: TextTrackList): PlayerAdBreak[] {
    const data = this.vodAdBreakParser.parseAdBreaks({ textTrackList });

    return data.adBreaks;
  }

  parseGt12CHAdBreaks(manifest: string, options: PlayerAdBreaksParserOptions, textTrackList?: TextTrackList): PlayerAdBreaksInfo {
    const { adBreaks, segments } = this.gt12CHAdBreaksParser.parseAdBreaks({ textTrackList, manifest, shouldParseInsertedAds: options.shouldParsePreRoll });

    return {
      adBreaks,
      segments
    };
  }

  parseFfwdAdBreaks(textTrackList?: TextTrackList): PlayerAdBreak[] {
    const data = this.ffwdAdBreakParser.parseAdBreaks({ textTrackList });

    return data.adBreaks;
  }

  parseDpgAdBreaks(manifest: string, options: PlayerAdBreaksParserOptions, textTrackList?: TextTrackList): PlayerAdBreaksInfo {
    const { adBreaks, segments } = this.dpgAdBreakParser.parseAdBreaks({ textTrackList, manifest, shouldParseInsertedAds: options.shouldParsePreRoll });

    return {
      adBreaks,
      segments
    };
  }
}