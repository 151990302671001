import { Components } from '../consts/component';
import { buildAnalyticMessage } from '../utils/buildAnalyticMessage';
import { PlayerEventType } from './eventTypes';
import { PlayerEvent } from './playerEvent';

export enum PlayerErrorCode {
    EmptyManifestUrl = 2,
    LoadFailed,
    UnloadFailed,
    External,
    PlayFailed,
    AdError,
    BrowserIsNotSupported = -2036,
}

export class ErrorAnalyticsData {
    isMuted: boolean;
    isPageVisible: boolean;

    constructor(isMuted: boolean, isPageVisible: boolean) {
        this.isMuted = isMuted;
        this.isPageVisible = isPageVisible;
    }
}
export class PlayerErrorEvent extends PlayerEvent {
    public readonly code: number = -1;
    public readonly description?: string; //debug purpose only
    public analyticsMessage?: string;


    constructor() {
        super(PlayerEventType.Error);
    }
}

export class EmptyManifestUrlErrorEvent extends PlayerErrorEvent {
    code = PlayerErrorCode.EmptyManifestUrl;
    description = 'no manifest url';

    constructor() {
        super();

        this.analyticsMessage = buildAnalyticMessage({
            component: Components.webPlayer,
            code: this.code,
            data: [this.description],
        });
    }
}

export class BrowserIsNotSupportedPlayerErrorEvent extends PlayerErrorEvent {
    code = PlayerErrorCode.BrowserIsNotSupported;
    description = 'browser is not supported';

    constructor() {
        super();

        this.analyticsMessage = buildAnalyticMessage({
            component: Components.webPlayer,
            code: this.code,
            data: [this.description],
        });
    }
}

export class LoadFailedPlayerErrorEvent extends PlayerErrorEvent {
    code = PlayerErrorCode.LoadFailed;
}

export class UnLoadFailedPlayerErrorEvent extends PlayerErrorEvent {
    code = PlayerErrorCode.UnloadFailed;
}

export class PlayFailedPlayerErrorEvent extends PlayerErrorEvent {
    code = PlayerErrorCode.PlayFailed;
}

export class ExternalPlayerErrorEvent extends PlayerErrorEvent {
    code = PlayerErrorCode.External;

    constructor(
        public readonly externalPlayerErrorCode: number,
        public readonly externalPlayerErrorData: unknown,
        public readonly externalPlayerErrorName: string,
        public readonly analyticsData: ErrorAnalyticsData,
        public readonly httpCode: number | null,
        public readonly isStreamingError: boolean,
    ) {
        super();

        this.analyticsData = analyticsData;
        this.analyticsMessage = buildAnalyticMessage({
            component: Components.shakaPlayer,
            code: externalPlayerErrorCode,
            name: externalPlayerErrorName,
            data: externalPlayerErrorData,
        });
    }
}

export class PlayerAdErrorEvent extends PlayerEvent {
    code = PlayerErrorCode.AdError;

    constructor(
        public readonly errorCode: string,
        public readonly manifestUrl: string,
    ) {
        super(PlayerEventType.AdError);
    }
}
