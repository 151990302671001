import { dissoc, trim } from 'ramda';
import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { DpgSegmentationType } from '../../../../consts/segmentationTypes';
import {
    ParseScte,
    PlayerAdBreak,
    PlayerAdBreaksInfo,
    PlayerScteSegment,
} from '../../../../shared';
import {
    getAllManifestPeriods,
    getEventNodes,
    getEventStreamNodes,
    PeriodInfo,
} from '../../../manifest/dash';
import InsertedAdBreaksParser, { InsertedAdBreakParserDeps } from './insertedAdBreaksParser';

export interface DpgAdBreakParserDeps extends InsertedAdBreakParserDeps {
    readonly parseScte: ParseScte;
}

interface PlayerScteDpgSegment extends PlayerScteSegment {
    data: string;
}

export class DpgDashAdBreaksParserStrategy extends InsertedAdBreaksParser {
    static ATTR_AD_ID = 'urn:mime:text';

    private readonly parseScte: ParseScte;

    constructor(deps: DpgAdBreakParserDeps) {
        super(deps);

        this.parseScte = deps.parseScte;
    }

    protected get adBreaksType(): AdBreaksParsingType {
        return AdBreaksParsingType.dpg;
    }

    public parseAdBreaks(manifest: Document): PlayerAdBreaksInfo {
        const periodInfo = getAllManifestPeriods(manifest);

        if (!periodInfo) {
            return {
                adBreaks: [],
            };
        }

        const segments = this.parseSegments(periodInfo);
        const segmentAdBreaks = this.getAdBreaksFromSegments(segments);

        const insertedAdBreaks = this.parseInsertedAdBreaks(periodInfo);

        const adBreaks = this.sortAdBreaks([
            ...insertedAdBreaks, 
            ...segmentAdBreaks,
        ]);

        return { adBreaks, segments };
    }

    private getEventNodes(period: Element): Element[] {
        return getEventStreamNodes(period).filter(
            (eventStream) => eventStream.getAttribute('schemeIdUri') === DpgDashAdBreaksParserStrategy.ATTR_AD_ID
        );
    }

    /**
     * SCTE segment starts in the beginning of the DASH period where the opening SCTE35 marker 
     * appears first and the SCTE segment ends at the beginning of any next period where the 
     * very same opening SCTE35 marker is absent.
     * 
     * @see
     * https://wikiprojects.upc.biz/display/PERS/Linear+Ad+Breaks+-+SCTE35#LinearAdBreaksSCTE35-DASH-SCTESegmentationApproach
     */
    private getRepeatedSegment(
        segments: PlayerScteDpgSegment[],
        periodsInfo: PeriodInfo[],
        periodIndex: number,
        segmentData: string): PlayerScteDpgSegment | null {
        if (periodIndex <= 0) {
            return null;
        }

        const lastSameSegmentIdx = segments.findIndex((segment) => segment.data === segmentData);

        if (lastSameSegmentIdx == -1) {
            return null;
        }

        const previousPeriod = periodsInfo[periodIndex - 1];
        const lastSameSegment = segments[lastSameSegmentIdx];

        if (!lastSameSegment || previousPeriod?.end !== lastSameSegment?.endTime) {
            return null;
        }

        return lastSameSegment;
    }

    private parseSegments(periodsInfo: PeriodInfo[]): PlayerScteSegment[] {
        const segments: PlayerScteDpgSegment[] = [];

        periodsInfo.forEach((periodInfo: PeriodInfo, periodIndex) => {
            const { period } = periodInfo;

            this.getEventNodes(period).forEach((eventStream) =>
                getEventNodes(eventStream).forEach((event) => {
                    const { start, end } = periodInfo;

                    const scteId = this.parseScte(event.textContent || '');
                    const data = trim(event.textContent || '');

                    const previousPeriodSegment = this.getRepeatedSegment(
                        segments, periodsInfo, periodIndex, data,
                    );

                    if (previousPeriodSegment) {
                        previousPeriodSegment.endTime = end;

                        return;
                    }

                    segments.push({
                        data: data,
                        startTime: start,
                        endTime: end,
                        segmentationTypeId: scteId && `0x${scteId}`,
                    });
                }));
        });

        return segments.map(dissoc('data'));
    }

    private getAdBreaksFromSegments(scteSegments: PlayerScteSegment[]): PlayerAdBreak[] {
        return scteSegments.reduce((adBreaks: PlayerAdBreak[], segment) => {
            if (this.isAdSegment(segment.segmentationTypeId || '')) {
                adBreaks.push({
                    startTime: segment.startTime,
                    endTime: segment.endTime,
                    adType: AdBreaksParsingType.dpg,
                });
            }

            return adBreaks;
        }, []);
    }

    private isAdSegment(type: string): boolean {
        return type === DpgSegmentationType.BREAK || type === DpgSegmentationType.PROVIDER_AD;
    }
}