const MAX_PATCH_LENGTH = 16000;

export const fromCharCode = (charCodes: Uint8Array): string => {
	let str = '';

	for (let i = 0; i < charCodes.length; i += MAX_PATCH_LENGTH) {
		const subArray = charCodes.slice(i, i + MAX_PATCH_LENGTH);

		str += String.fromCharCode.apply(null, [...subArray]);
	}

	return str;
};

export const fromUtf8 = (data?: ArrayBuffer|ArrayBufferView): string => {
	if (!data) {
		return '';
	}

	const utf8 = fromCharCode(new Uint8Array(<ArrayBuffer>data));

	try {
		return decodeURIComponent(utf8);
	} catch (e) {
		return '';
	}
};

export const toUtf8 = (str: string): ArrayBuffer => {
	let utf8 = '';

	if (str) {
		const encoded = encodeURIComponent(str);

		utf8 = unescape(encoded);
	}

	const result = new Uint8Array(utf8.length);

	for (let i = 0; i < utf8.length; ++i) {
		result[i] = utf8.charCodeAt(i);
	}

	return result.buffer;
};