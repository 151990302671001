import DataCue from '../../../../dataCue';
import { HlsAdBreaksParserOptions, PlayerAdBreak, PlayerAdBreaksInfo, PlayerScteSegment } from '../../../../shared';
import { getPlaylistStart } from '../../../hls/playlist';
import { getMetadataCues } from '../../../hls/video';
import InsertedAdBreaksParserService from '../insertedAdBreaksParser/insertedAdBreaksParser';
import {
    DateRangeData,
    parseDataCueData
} from '../utils';
import { createPlayerScteSegments, CueInfo, parseData, Segment, sortSegmentsByStartTime } from './utils';

export interface LinearAvadHlsAdBreaksParser {
    parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo;
}
/**
 * HLS Linear avad ad breaks parser.
 * 
 * see:
 * https://wikiprojects.upc.biz/display/PERS/Linear+Ad+Breaks+-+SCTE35#LinearAdBreaksSCTE35-HLS
 */
export default class LinearAdBreaksParserService extends InsertedAdBreaksParserService {
    static readonly AD_ATTRIBUTE = 'X-COM-DMDSDP-AVAD';
    static readonly DATA_TYPE = 'DATERANGE-SCTE35-TXT';
    static readonly DATA_ENCODING = 'BASE64';
    static readonly SCTE_IN = 'SCTE-IN';
    static readonly SCTE_OUT = 'SCTE-OUT';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected getPlayerAdBreaksFromSegments(_segments: PlayerScteSegment[]): PlayerAdBreak[] {
        return [];
    }

    public parseAdBreaks(options: HlsAdBreaksParserOptions): PlayerAdBreaksInfo {
        const { textTrackList, manifest, shouldParseInsertedAds } = options;

        if (!textTrackList || !manifest) {
            return {
                adBreaks: []
            };
        }

        const startTime = getPlaylistStart(manifest) ?? 0;

        const metadataTracks = getMetadataCues(textTrackList);

        if (!metadataTracks) {
            return {
                adBreaks: []
            };
        }

        const dateRangesData = this.parseAdBreaksDateRanges([...metadataTracks]);

        const cuesInfo = this.createCueInfo(dateRangesData, startTime);
        const groupedCues = this.groupCues(cuesInfo);

        const scteSegments = createPlayerScteSegments(groupedCues);
        const playerAdBreaks = this.getPlayerAdBreaksFromSegments(scteSegments);

        const insertedAdBreaks = shouldParseInsertedAds == true ? this.parseInsertedAdBreaks(textTrackList) : [];

        const allBreaks = [...insertedAdBreaks, ...playerAdBreaks];

        const sortedAdBreaks = allBreaks.sort((ad1, ad2) =>
            ad1.startTime - ad2.startTime
        );

        return {
            adBreaks: sortedAdBreaks,
            segments: scteSegments
        };
    }

    private parseAdBreaksDateRanges(metadataTracks: TextTrackCue[]): DateRangeData[] {
        return metadataTracks.map((dataCue) => dataCue as DataCue)
            .filter((dataCue) => dataCue.value.key === LinearAdBreaksParserService.AD_ATTRIBUTE)
            .map((dataCue) => dataCue.value.data)
            .map(parseDataCueData);
    }

    private createCueInfo(adBreaksData: DateRangeData[], playlistStartTime: number): CueInfo[] {
        return adBreaksData
            .filter((data) => this.validateDateRange(data))
            .reduce((acc: CueInfo[], adData: DateRangeData): CueInfo[] => {
                const { data = '', event = '' } = adData;

                const dataInfo = parseData(data);

                if (!dataInfo) {
                    return acc;
                }

                const { startTime, endTime, duration, typeId, id } = dataInfo;

                if (!startTime || !typeId || !id) {
                    return acc;
                }

                if (event === LinearAdBreaksParserService.SCTE_IN && (!endTime && !duration)) {
                    return acc;
                }

                const cueInfo = {
                    type: event,
                    startTime: startTime - playlistStartTime,
                    endTime: endTime && endTime - playlistStartTime,
                    duration,
                    typeId,
                    id,
                };

                return [...acc, cueInfo];
            }, []);
    }

    private groupCues(cues: CueInfo[]): Segment[] {
        const pairs = cues.reduce((acc: Segment[], cue: CueInfo): Segment[] => {
            if (cue.type !== LinearAdBreaksParserService.SCTE_OUT) {
                return acc;
            }

            const end = cues.find((c: CueInfo) => c.type === LinearAdBreaksParserService.SCTE_IN && c.id === cue.id);


            const segment = {
                start: cue,
                end,
            };

            return [...acc, segment];
        }, []);

        return sortSegmentsByStartTime(pairs);
    }

    protected validateDateRange(dateRange: DateRangeData): boolean {
        if (!dateRange.data) {
            return false;
        }

        return (
            (dateRange.event === LinearAdBreaksParserService.SCTE_IN || dateRange.event === LinearAdBreaksParserService.SCTE_OUT)
            && dateRange.dataType === LinearAdBreaksParserService.DATA_TYPE
            && dateRange.dataEncoding === LinearAdBreaksParserService.DATA_ENCODING
        );
    }
}