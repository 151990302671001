import { AdBreaksParsingType, OpportunityType } from '../../../../consts/adBreaks';
import ManifestTracks from '../../../../externalPlayer/implementations/shakaExternalPlayer/models/manifestTracks';
import { OpportunityInfo, PlacementInfo as ParsedPlacementInfo, ParseOpportunity, ParsePlacement, PlayerAdBreak, PlayerAdBreaksInfo, PlayerAdBreaksParserOptions } from '../../../../shared';
import { getNodeByTagName, PeriodInfo } from '../../../manifest';
import { memoizeWith } from '../../../memoize';
import { DashAdBreaksParser } from './defaultAdBreaksParser';


export type PlacementInfo = {
    opportunity: Element,
    adBreak: PlayerAdBreak,
};

export interface InsertedAdBreakParserDeps {
    readonly parsePlacement: ParsePlacement;
    readonly parseOpportunity: ParseOpportunity;
}

/**
 * Vod ad breaks parsing strategy for DASH Manifest
 * 
 * see Opportunity - DASH and Placement - DASH in:
 * https://wikiprojects.upc.biz/display/PERS/Inserted+Ads
 */
export default class InsertedAdBreaksParser implements DashAdBreaksParser {
    protected readonly parsePlacement: (vast: string, id: number) => ParsedPlacementInfo | null;
    protected readonly parseOpportunity: (vast: string, id: number) => OpportunityInfo | null;

    static ATTR_ENCODING = 'encoding';
    static ATTR_COMPRESSION = 'compression';
    static NONE_COMPRESSION = 'none';

    static LGAD_PAYLOAD_TAG = 'LGAd:Payload';
    static ENCODING_BASE64 = 'xs:base64Binary';
    static LGAD_PLACEMENT_TAG = 'LGAd:PlacementStart';
    static LGAD_OPPORTUNITY_TAG = 'LGAd:OpportunityStart';
    static EVENT_STREAM_SCHEME = 'urn:LGAd:adEvent:2015';

    static AD_EVENT_STREAM_SELECTOR = `EventStream[schemeIdUri='${InsertedAdBreaksParser.EVENT_STREAM_SCHEME}']`;

    constructor(deps: InsertedAdBreakParserDeps) {
        this.parsePlacement = memoizeWith((_, id) => String(id), (vast) => deps.parsePlacement(atob(vast)));
        this.parseOpportunity = memoizeWith((_, id) => String(id), (vast) => deps.parseOpportunity(atob(vast)));
    }

    protected isAdPayloadValid(adPayload: Element): boolean {
        const encoding = adPayload.getAttribute(InsertedAdBreaksParser.ATTR_ENCODING);
        const compression = adPayload.getAttribute(InsertedAdBreaksParser.ATTR_COMPRESSION);

        return (
            encoding === InsertedAdBreaksParser.ENCODING_BASE64
            && compression === InsertedAdBreaksParser.NONE_COMPRESSION
        );
    }

    protected findEventStream(period: Element): Element | null {
        return period.querySelector(InsertedAdBreaksParser.AD_EVENT_STREAM_SELECTOR);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected checkOpportunityType(_type?: OpportunityType): boolean {
        return true;
    }

    protected getAdPayload(node: Element): Element | null {
        const payload = getNodeByTagName(node, InsertedAdBreaksParser.LGAD_PAYLOAD_TAG);

        if (!payload || !this.isAdPayloadValid(payload)) {
            return null;
        }

        return payload;
    }

    protected findEventOpportunity(event: Element): Element | null {
        const opportunity = getNodeByTagName(event, InsertedAdBreaksParser.LGAD_OPPORTUNITY_TAG);

        if (!opportunity) {
            return null;
        }

        if (!this.getAdPayload(opportunity)) {
            return null;
        }

        return opportunity;
    }

    protected getPlayerAdBreak(
        periodInfo: PeriodInfo,
        adPayload: Element,
        opportunityInfo: OpportunityInfo | null,
        opportunityType: OpportunityType | undefined): PlayerAdBreak {
        const periodStart = periodInfo.start;
        const periodEnd = Math.floor(periodInfo.start + (periodInfo.duration || 0));

        const placement = this.parsePlacement(adPayload.textContent || '', periodStart);


        const adBreakStartTime = opportunityInfo?.timeOffsetMerged ?? 0;
        const adBreakEndTime = adBreakStartTime + (opportunityInfo?.secondsAdded ?? 0);
        const isAdPartOfAdBreak = (periodStart >= adBreakStartTime) && (periodStart <= adBreakEndTime);
       
        const adBreak = {
            startTime: periodStart,
            endTime: periodEnd,
            payload: placement?.reportingMetadata,
            adType: this.adBreaksType,
            opportunityType,
            oppSecondsDelta: opportunityInfo?.secondsDelta,
            oppTimeOffsetMerged: opportunityInfo?.timeOffsetMerged,
            placementCategory: placement?.category,
            oppSecondsAdded: isAdPartOfAdBreak ? opportunityInfo?.secondsAdded : undefined,
            oppTrackingEvents: isAdPartOfAdBreak ? opportunityInfo?.trackingEvents : [],
        };

        return adBreak;
    }

    protected get adBreaksType(): AdBreaksParsingType {
        return AdBreaksParsingType.none;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public parseAdBreaks(_manifest: Document, _options: PlayerAdBreaksParserOptions): PlayerAdBreaksInfo {
        return {
            adBreaks: []
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public parseManifestAudioTracks(_manifest: Document, _adBreaks: PlayerAdBreak[]): ManifestTracks | null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public parseManifestSubtitlesTracks(_manifest: Document, _adBreaks: PlayerAdBreak[]): ManifestTracks | null {
        return null;
    }

    protected parseInsertedAdBreaks(periodNodes: PeriodInfo[]): PlayerAdBreak[] {
        const adBreaks = periodNodes.reduce((acc: Array<PlacementInfo | null>, periodInfo: PeriodInfo): Array<PlacementInfo | null> => {
            const { period } = periodInfo;

            const accWithoutAdBreak = [...acc, null];

            const eventStream = this.findEventStream(period);

            if (!eventStream) {
                return accWithoutAdBreak;
            }

            const placementStart = getNodeByTagName(eventStream, InsertedAdBreaksParser.LGAD_PLACEMENT_TAG);

            if (!placementStart) {
                return accWithoutAdBreak;
            }

            const adPayload = this.getAdPayload(placementStart);

            if (!adPayload) {
                return accWithoutAdBreak;
            }

            const opportunity = acc[acc.length - 1]?.opportunity || this.findEventOpportunity(eventStream);

            if (!opportunity) {
                return accWithoutAdBreak;
            }

            const opportunityInfo = this.parseOpportunity(opportunity.textContent?.trim() || '', periodInfo.start);

            const opportunityType = opportunityInfo?.type;

            if (!this.checkOpportunityType(opportunityType)) {
                return accWithoutAdBreak;
            }

            const adBreak = this.getPlayerAdBreak(periodInfo, adPayload, opportunityInfo, opportunityType);

            return [...acc, { opportunity, adBreak }];
        }, []);

        const advertisement = adBreaks.reduce((acc: PlayerAdBreak[], data: PlacementInfo | null): PlayerAdBreak[] => {
            const adBreak = data?.adBreak;

            if (adBreak) {
                acc.push(data.adBreak);
            }

            return acc;
        }, []);

        return advertisement;
    }

    protected sortAdBreaks(adBreaks: PlayerAdBreak[]): PlayerAdBreak[] {
        return adBreaks.sort((adBreak1, adBreak2) => adBreak1.startTime - adBreak2.startTime);
    }
}