import { extern, util } from '@shakaPlayer';
import { AdBreaksParsingType } from '../../../consts/adBreaks';
import { ProtectionSystemType } from '../../../consts/drm';
import { INFINITE_DURATION } from '../../../consts/duration';
import { ManifestType } from '../../../consts/manifestType';
import { StreamingProtocol } from '../../../consts/protocol';
import { getMediaPlaylistsDataUrl } from '../../../utils/hls/playlist';
import HlsManifestParser from '../../../utils/manifestParser/hls/hlsManifestParser';
import { ExternalPlayerDurationChangedEvent } from './../../externalPlayerEvent';
import ShakaExternalPlayer, { ManifestData } from './shakaExternalPlayer';

const DURATION_CHANGE_INTERVAL_TIME = 1000;

export default class ShakaHlsExternalPlayer extends ShakaExternalPlayer {
  protected drmPriority: ProtectionSystemType[] = [ProtectionSystemType.Fairplay_1];
  protected readonly mimeType = 'application/x-mpegurl';
  public readonly protocol = StreamingProtocol.Hls;
  protected manifestData: ManifestData | null = null;
  protected hasPlaybackStarted = false;
  private manifestParser: HlsManifestParser | null = null;
  private ignoredScteTypes: number[] = [];
  private durationChangedInterval: number | null = null;

  public setStyles(): void {
    const style = window.document.createElement('style');

    style.textContent = getCaptionStyles();
    window.document.head.appendChild(style);
  }

  protected initDataTransform = (
    initData: Uint8Array,
    initDataType: string,
    drmInfo: extern.DrmInfo | null
  ): Uint8Array => {
    if (initDataType != 'skd') {
      return initData;
    }

    const skdUri = util.StringUtils.fromBytesAutoDetect(initData);

    this.keyId = this.getKeyId(skdUri);

    const contentId = util.FairPlayUtils.defaultGetContentId(initData);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cert = drmInfo!.serverCertificate;

    return util.FairPlayUtils.initDataTransform(initData, contentId, cert);
  };

  protected async preload(
    masterPlaylistUrl: string,
    masterPlaylist: string,
    mediaPlaylist?: string
  ): Promise<void> {
    if (this.shouldPreloadManifest) {
      await this.preloadMasterAndMediaPlaylists(masterPlaylistUrl);

      return;
    }

    if (this.shouldPreloadMediaPlaylistManifest && masterPlaylist) {
      if (mediaPlaylist) {
        this.manifestData = { masterPlaylist, mediaPlaylist };

        return;
      }

      await this.loadMediaPlaylist(masterPlaylistUrl, masterPlaylist);
    }
  }

  protected async preloadMasterAndMediaPlaylists(masterPlaylistUrl: string): Promise<void> {
    try {
      const { data: masterPlaylist } = await this.request(masterPlaylistUrl);

      await this.loadMediaPlaylist(masterPlaylistUrl, masterPlaylist);
    } catch (e) {
      this.logger.error('failed to preload manifest: ', e);
    }
  }

  protected async loadMediaPlaylist(
    masterPlaylistUrl: string,
    masterPlaylist: string
  ): Promise<void> {
    try {
      const allMediaPlaylists = getMediaPlaylistsDataUrl(masterPlaylist, masterPlaylistUrl);

      const mediaPlaylistUrl = allMediaPlaylists[0];

      if (!mediaPlaylistUrl) {
        throw Error();
      }

      const { data: mediaPlaylist } = await this.request(mediaPlaylistUrl);

      this.manifestData = { masterPlaylist, mediaPlaylist };
    } catch (e) {
      this.logger.error('failed to preload manifest: ', e);
    }
  }

  protected configureManifestParser(): void {
    this.manifestParser = new HlsManifestParser({
      parseOpportunity: this.parseOpportunity,
      parsePlacement: this.parsePlacement,
      parseScte: this.parseScte,
      ignoredScteTypes: this.ignoredScteTypes,
    });
  }

  protected parseManifest(mediaPlaylist: string): void {
    if (!this.manifestParser || this.adBreakType === AdBreaksParsingType.none) {
      return;
    }

    if (this.adBreakType === AdBreaksParsingType.basic) {
      this.playerAdBreaks = this.manifestParser.parseBasicAdBreaks(mediaPlaylist);
    }

    if (this.adBreakType === AdBreaksParsingType.gt12) {
      const textTracks = this.shakaPlayer.getMediaElement()?.textTracks;

      const { adBreaks, segments } = this.manifestParser.parseGt12CHAdBreaks(
        mediaPlaylist,
        { shouldParsePreRoll: this.shouldParsePreRoll },
        textTracks
      );

      this.playerAdBreaks = adBreaks;
      this.playerScteSegments = segments || [];
    }

    if (this.adBreakType === AdBreaksParsingType.vod) {
      const textTracks = this.shakaPlayer?.getMediaElement()?.textTracks;

      this.playerAdBreaks = this.manifestParser.parseVodAdBreaks(textTracks);
    }

    if (this.adBreakType === AdBreaksParsingType.ffwd) {
      const textTracks = this.shakaPlayer?.getMediaElement()?.textTracks;

      this.playerAdBreaks = this.manifestParser.parseFfwdAdBreaks(textTracks);
    }

    if (this.adBreakType === AdBreaksParsingType.dpg) {
      const textTracks = this.shakaPlayer.getMediaElement()?.textTracks;

      const { adBreaks, segments } = this.manifestParser.parseDpgAdBreaks(
        mediaPlaylist,
        { shouldParsePreRoll: true },
        textTracks
      );

      this.playerAdBreaks = adBreaks;
      this.playerScteSegments = segments || [];
    }
  }

  private getKeyId(skdUri: string): string | null {
    const url = new URL(skdUri);

    return url.searchParams.get('KeyId');
  }

  configureStreaming(): void {
    this.shakaPlayer.configure('streaming.useNativeHlsOnSafari', true);
    super.configureStreaming();
  }

  protected shouldRecoverError(): boolean {
    return this.hasVideoBeenStarted();
  }

  protected isAudioDescription(track: extern.Track): boolean {
    const { kind } = track;

    return kind === 'description';
  }

  protected isHardOfHearing(track: extern.Track): boolean {
    const { kind } = track;

    return kind === 'captions';
  }

  protected disableTextTrack(currentTextTracks: TextTrackList): void {
    for (const textTrack of currentTextTracks) {
      if (textTrack.mode != 'disabled') {
        textTrack.mode = 'disabled';
      }
    }
  }

  protected checkVariant(track: extern.Track, language: string, role: string): boolean {
    const isTrackTheSame = track.language === language && role && role === track.roles[0];

    return Boolean(isTrackTheSame);
  }

  protected getSeekableDuration(): number | undefined {
    const seekableTimeRange = this.shakaPlayer.getMediaElement()?.seekable;

    if (!seekableTimeRange) {
      return;
    }

    return seekableTimeRange.end(0) - seekableTimeRange.start(0);
  }

  protected getManifestType(): string {
    const currentDuration = this.shakaPlayer.getMediaElement()?.duration;

    if (currentDuration === INFINITE_DURATION || currentDuration === Infinity) {
      return ManifestType.Dynamic;
    }

    return ManifestType.Static;
  }

  private get shouldPreloadManifest(): boolean {
    return this.adBreakType === AdBreaksParsingType.basic;
  }

  private get shouldPreloadMediaPlaylistManifest(): boolean {
    return (
      this.adBreakType === AdBreaksParsingType.gt12 || this.adBreakType === AdBreaksParsingType.dpg
    );
  }

  private clearDurationChangeInterval() {
    if (this.durationChangedInterval) {
      clearInterval(this.durationChangedInterval);
      this.durationChangedInterval = null;
    }
  }

  public onLoadedData(): void {
    let prevDuration = this.duration;

    this.clearDurationChangeInterval();

    this.durationChangedInterval = window.setInterval(() => {
      const currentDuration = this.duration;

      if (prevDuration == currentDuration) {
        return;
      }

      this.dispatchEvent(new ExternalPlayerDurationChangedEvent());

      prevDuration = currentDuration;
    }, DURATION_CHANGE_INTERVAL_TIME);

    this.parseManifestAndEmit(this.manifestData?.mediaPlaylist || '');
  }

  public clear(): void {
    super.clear();

    this.clearDurationChangeInterval();

    this.playerAdBreaks = [];
    this.manifestData = null;
    this.hasPlaybackStarted = false;
  }

  public get shouldHandleStartPosition(): boolean {
    return !this.hasPlaybackStarted;
  }

  public setPlaybackStartState(): void {
    this.hasPlaybackStarted = true;
  }

  public setIgnoredScteTypes(ignoredScteTypes?: number[]): void {
    if (!ignoredScteTypes) {
      return;
    }

    this.ignoredScteTypes = ignoredScteTypes;
  }

  public shouldWaitForCanPlayEventAfterSeek(): boolean {
    return this.shakaPlayer.getMediaElement()?.paused ?? false;
  }
}

function getCaptionStyles() {
  return `
  video::-webkit-media-text-track-display {
    height: 100%;
    position: initial;
    transform: translateY(75%);
  }
  `;
}
