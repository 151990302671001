import { AdBreaksParsingType } from '../../../../consts/adBreaks';
import { PlayerAdBreak, PlayerAdBreaksInfo } from '../../../../shared';
import { getAllManifestPeriods, PeriodInfo } from '../../../manifest';
import { DashAdBreaksParser } from './defaultAdBreaksParser';

const SECOND_IN_MS = 1000;

export class BasicDashAdBreaksParserStrategy implements DashAdBreaksParser {
    /**
     * Period is considered as Ad-Break when the value of schemeIdUri equals CanonicalFullAdIDIdentifierType
     * @see https://registry.smpte-ra.org/view/published/types_view.html
     */
    static ATTR_AD_ID = 'urn:smpte:ul:060E2B34.01040101.01200900.00000000';

    static AD_SELECTOR = `[schemeIdUri="${BasicDashAdBreaksParserStrategy.ATTR_AD_ID}"]`;

    public parseAdBreaks(manifest: Document): PlayerAdBreaksInfo {
        const periodInfo = getAllManifestPeriods(manifest);

        if (!periodInfo) {
            return {
                adBreaks: []
            };
        }

        const adBreaks = periodInfo.reduce((acc: PlayerAdBreak[], periodInfo: PeriodInfo): PlayerAdBreak[] => {
            const { period } = periodInfo;

            const adIdentification = period.querySelector(BasicDashAdBreaksParserStrategy.AD_SELECTOR);
        
            if (!adIdentification) {
              return acc;
            }

            const startTime = periodInfo.start;
            const endTime = startTime + (periodInfo.duration || SECOND_IN_MS);
        
            const playerAdBreak = {
                startTime, 
                endTime,
                adType: AdBreaksParsingType.basic,
            };
        
            return [...acc, playerAdBreak];
        }, []);
        
        return { adBreaks };
    }
    
    public parseManifestAudioTracks(): null {
        return null;
    }

    public parseManifestSubtitlesTracks(): null {
        return null;
    }
}