import { equals } from 'ramda';
import { text } from '@shakaPlayer';

/**
 * This is the copy of shaka.util.ArrayUtils.equal implementation
 * which is not exported in release version of shaka-player
 * @see https://shaka-player-demo.appspot.com/docs/api/shaka.util.ArrayUtils.html#.equal
 *
 * Determines if the given arrays contain equal elements in the same order.
 *
 * @param {!Array.<T>} a
 * @param {!Array.<T>} b
 * @param {function(T, T):boolean=} compareFn
 * @return {boolean}
 * @template T
 */
const _equal = <T>(a: T[], b: T[], compareFn: (a: T, b: T) => boolean = equals) => {
  if (a.length != b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    const el1 = a[i] as T;
    const el2 = b[i] as T;

    if (!compareFn(el1, el2)) {
      return false;
    }
  }

  return true;
};

/**
 * As shaka.text.Cue.equal is not exported in the release version of the lib,
 * it's implementation has been copied as it's used in the  custom text displayer
 * @see https://shaka-player-demo.appspot.com/docs/api/shaka.text.Cue.html#.equal
 *
 * Check if two Cues have all the same values in all properties.
 * @param {!shaka.text.Cue} cue1
 * @param {!shaka.text.Cue} cue2
 * @return {boolean}
 * @suppress {checkTypes} since we must use [] and "in" with a struct type.
 */
export const areCuesEqual = (cue1: text.Cue, cue2: text.Cue): boolean => {
  // Compare the start time, end time and payload of the cues first for
  // performance optimization.  We can avoid the more expensive recursive
  // checks if the top-level properties don't match.
  // See: https://github.com/shaka-project/shaka-player/issues/3018
  if (
    cue1.startTime != cue2.startTime ||
    cue1.endTime != cue2.endTime ||
    cue1.payload != cue2.payload
  ) {
    return false;
  }

  let k: keyof text.Cue;

  for (k in cue1) {
    if (k == 'startTime' || k == 'endTime' || k == 'payload') {
      // Already compared.
    } else if (k == 'nestedCues') {
      // This uses shaka.text.Cue.equal rather than just this.equal, since
      // otherwise recursing here will unbox the method and cause "this" to be
      // undefined in deeper recursion.
      if (!_equal(cue1.nestedCues, cue2.nestedCues, areCuesEqual)) {
        return false;
      }
    } else if (k == 'region' || k == 'cellResolution') {
      for (const k2 in cue1[k]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (cue1[k][k2] != cue2[k][k2]) {
          return false;
        }
      }
    } else if (Array.isArray(cue1[k])) {
      if (!_equal(cue1[k], cue2[k])) {
        return false;
      }
    } else {
      if (cue1[k] != cue2[k]) {
        return false;
      }
    }
  }

  return true;
};
