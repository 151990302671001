export enum ShakaPlayerEventType {
    // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:BufferingEvent
    Buffering = 'buffering',
    // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:TextChangedEvent
    TextChanged = 'textchanged',
    // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:VariantChangedEvent
    VariantChanged = 'variantchanged',
    // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:ErrorEvent
    Error = 'error',
    // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:ExpirationUpdatedEvent
    ExpirationUpdated = 'expirationupdated',
    // // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:AdaptationEvent
    Adaptation = 'adaptation',
}
