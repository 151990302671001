import { PlayerEventType } from './eventTypes';
import { PlayerEvent } from './playerEvent';

export class PlayerTrack {
    constructor(
        public readonly id: string,
        public readonly language: string,
        public readonly isActive: boolean,
        public readonly role: string
    ) {}
}

export class PlayerTextTrack extends PlayerTrack {}

export class PlayerAudioTrack extends PlayerTrack {}


export class PlayerTextTracksUpdateEvent extends PlayerEvent {
    constructor(public readonly textTracks: PlayerTextTrack[]) {
        super(PlayerEventType.TextTracksUpdate);
    }
}

export class PlayerAudioTracksUpdateEvent extends PlayerEvent {
    constructor(public readonly audioTracks: PlayerAudioTrack[]) {
        super(PlayerEventType.AudioTracksUpdate);
    }
}